import { variablemanual_standalone_accociation_value } from "typescript_helpers"
import { VM } from "./variablenmanual/Variablemanual2"

export const Test = () => {
  return (
    <>
      <VM association={variablemanual_standalone_accociation_value}/>
    </>
  )
}
