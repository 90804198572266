import { useEffect, useState } from "react";
import { InputElementCheckbox, InputItem } from "../../../../text_inputs/main_content_inputs";
import { FormElementMRISubSelectionTarget } from "./mri_sub_selection_target";

export function FormElementMRISubSelection({ changeFunction }) {
  const box1_label = "MRI";

  const [box1_selected, setBox1_selected] = useState(false);

  useEffect(() => {
    changeFunction({ target: { value: box1_selected } }, "methods_setting_mri");
  }, [box1_selected])

  return (
    <>
      <InputItem>
        <InputElementCheckbox
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox1_selected(!box1_selected);
          }}
        />
      </InputItem>
      {box1_selected &&
        <FormElementMRISubSelectionTarget changeFunction={changeFunction} />
      }
    </>
  )

}


