import styled from "styled-components"
import { ThemeColor } from "../../../themes and styles/colors"
import { BorderSolidLightBlue, BorderSolidSlimBlack } from "../../../themes and styles/borders"

export const VMLRight = styled.div`
  position: relative;
  padding: 0.4rem;
  word-break: break-word;
  font-size: 0.9rem;
  background: ${props => props.background};
  ${BorderSolidSlimBlack}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`
export const VMLOuter = styled.div`
  display: grid;
  grid-template-columns: ${props => props.$template_columns || "auto 1fr"};
  align-items: center;
  overflow: hidden;
  gap: 0.8em;
  padding-top: 0.3rem;
  background: ${props => props.$background || ""};
`
export const CurrentNodeStyle = styled.div`
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidLightBlue}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`
