import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementPlanedAnalysis({ changeFunction }) {
  return (
    <InputElementContainerTopDownPositioning
      label={"What Kind Of Analysis Is Planed"}
    >
      <LabelSmall>
        Describe All Statistical Methods, Including Those Used To Control For Confounding
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_statistical_methods");
          }
        }
      />
      <LabelSmall>
        Describe Any Methods Used To Examine Subgroups And Interactions
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_exam_methods");
          }
        }
      />
      <LabelSmall>
        Explain How Missing Data Were Addressed
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_missing_data");
          }
        }
      />
      <LabelSmall>
        If Applicable, Explain How Loss To Follow-Up Was Addressed
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_loss_fu");
          }
        }
      />
      <LabelSmall>
        Describe Any Sensitivity Analyses
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_sensitivity_analyses");
          }
        }
      />
    </InputElementContainerTopDownPositioning>
  );
}


