import { useEffect, useState } from "react";
import { InputElementCheckbox, InputItem, OptionalTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementSettingSubOthers({ changeFunction }) {
  const box1_label = "Others";

  const [box1_selected, setBox1_selected] = useState(false);

  useEffect(() => {
    changeFunction(
      {
        target:
          { value: box1_selected }
      },
      "methods_setting_other"
    );
  }, [box1_selected])

  return (
    <>
      <InputItem>
        <InputElementCheckbox
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={
            (event) => {
              setBox1_selected(!box1_selected);
            }}
        />
      </InputItem>
      {box1_selected &&
        <OptionalTextArea
          placeholder={"Statement"}
          onChangeCallback={
            (event) => {
              changeFunction(event, "methods_setting_other_statement")
            }
          }
        />
      }
    </>
  )
}


