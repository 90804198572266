import { useEffect, useState } from "react";
import { FormHeader } from "./common_form_elemnts/FormHeader";
import { FormElementCoorperationPartners } from "./common_form_elemnts/further_information/partners";
import { FormElementAuthorList } from "./common_form_elemnts/further_information/author_list";

export function FormSectionFurtherInformation({ changeFunction }) {
  const [hasPartners, setHasPartners] = useState(false);
  const [hasFunding, setHasFunding] = useState(false);

  const updateHasPartners = (id) => {
    setHasPartners(id === "Yes")
  }

  const updateHasFunding = (id) => {
    setHasFunding(id === "Yes")
  }

  return (
    <>
      <FormHeader>Further Information</FormHeader>
      <FormElementCoorperationPartners
        changeFunction={changeFunction}
        hasPartners={hasPartners}
        updateHasPartners={updateHasPartners}
      />
      <FormElementAuthorList changeFunction={changeFunction} />
    </>
  )
}
