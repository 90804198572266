import { isFunction } from "typescript_helpers";
import { useProposalSelectionMapping } from "../../contexts/proposalsSelectionMapping/provider";
import { useEffect, useState } from "react";
import { CheckBox } from "../../widgets/checkbox";

function InjectedProposalSelectionMappingCheckbox ({Callback_selectionStatusChanged, association, variable_id, proposalSelectionMapping, scale}) {
  const [selected, setSelected] = useState(false);
  const { forceUpdateClock, selectionManager, setSelectionManager } = proposalSelectionMapping;

  const notifySelectioStatus = (status) => {
    if(isFunction(Callback_selectionStatusChanged)) {
      Callback_selectionStatusChanged(status);
    }
  }

  const handleCheckboxClick = () => {
    let newManager = selectionManager.setSelection(association.proposal_id, variable_id, association.kind, !selected);
    setSelectionManager(newManager);
    notifySelectioStatus(!selected);
  }

  const getSelectionStatusFromManager = () => {
    const isSelected = selectionManager.variableSelectedAsKindForProposal(variable_id, association.kind, association.proposal_id) !== false;
    setSelected(isSelected);
    notifySelectioStatus(isSelected);
  }

  useEffect(()=>{
    getSelectionStatusFromManager();
  },[])

  useEffect(()=>{
    getSelectionStatusFromManager();
  }, [forceUpdateClock])

  return (
    <CheckBox
      scale={scale}
      type={"checkbox"}
      checked={selected}
      onChange={handleCheckboxClick}
      />
  )
}


//selection is fully managed by the proposalSelectionMapping context that is available using the useProposalSelectionMapping hook
export function SelectVariableCheckbox( {Callback_selectionStatusChanged, association, variable_id, proposalSelectionMapping, scale} ) {
  const selection_mapping = useProposalSelectionMapping();
  let mapping = proposalSelectionMapping;
  if (proposalSelectionMapping === undefined) {
    mapping = selection_mapping
  }

  let scaling;
  if (scale === undefined) {
    scaling = "1.2";
  }
  else {
    scaling = scale;
  }

  return (
    <InjectedProposalSelectionMappingCheckbox 
      Callback_selectionStatusChanged={Callback_selectionStatusChanged}
      association={association}
      variable_id={variable_id}
      proposalSelectionMapping={mapping} 
      scale={scaling}
    />
  )
}
