import { Node } from "typescript_helpers";
import { useCurrentNodes } from "../../../contexts/currentNodes/provider";
import { ContextLink } from "./ContextLink";
import { VariableLink } from "./VariableLink";

export function VMLink({ node, association }) {
  const { nodesManager: currentNodesManager, setNodesManager: setCurrentNodesManager } = useCurrentNodes();

  const handleTextClick = () => {
    console.log(`in handleTextClick association is ${JSON.stringify(association)} and node.id ${node.id}`)
    let newManager = currentNodesManager.setCurrentNode(association.proposal_id, association.kind, node.id);
    setCurrentNodesManager(newManager);
  }

  if (Node.isVariable(node)) {
    return (
      <VariableLink
        association={association}
        node={node}
        handleTextClick={handleTextClick} />
    )
  }
  if (Node.isVMContext(node)) {
    return (
      <ContextLink
        handleTextClick={handleTextClick}
        displayName={node.id}
        $background={""}
      />
    )
  }
}
