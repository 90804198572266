import { useState } from "react";
import { useAuth } from '../../authentication/auth_provider';
import {
  BasicMenuAreaFormStyling,
  FormInputsWithPositioning
} from './form_layouts';
import { TextInputWithIconLeft } from "../text_inputs/menu_area_text_inputs";
import { UserIcon } from "../icons/font_awesome_icons";
import { Submit } from "../buttons/menu_area_buttons";
import { Padding } from "../text_display/main_content_text_styling";

function UserName({ callback }) {
  return (
    <TextInputWithIconLeft
      icon={UserIcon()}
      changeCallback={callback}
      placeholder_text="UKE-Mail"
      isVisible={true}
    />
  )
}

function Password({ callback, keyPressCallback }) {
  return (
    <TextInputWithIconLeft
      icon={UserIcon()}
      changeCallback={callback}
      placeholder_text="Passwort"
      isVisible={false}
      keyPressCallback={keyPressCallback}
    />
  )
}

export function LoginFormFunctionality({ Form }) {
  const [mail, updateMail] = useState("");
  const [password, updatePassword] = useState("");

  const nameChangeCallback = (currentContent) => {
    updateMail(currentContent);
  }

  const passwordChangeCallback = (currentContent) => {
    updatePassword(currentContent);
  }

  const { onLogin } = useAuth();
  const triggerLogin = () => {
    const userData = {
      'mail': mail,
      'password': password
    };
    onLogin(userData);
  }

  const passwordPressEnterCallback = (event) => {
    if (event.key === "Enter") {
      triggerLogin();
    }
  }

  return (
    <Form
      nameChangeCallback={nameChangeCallback}
      passwordChangeCallback={passwordChangeCallback}
      keyPressCallback={passwordPressEnterCallback}
      triggerLogin={triggerLogin}
    />
  )
}

export function LoginFormMinimal({ }) {
  const Styling = ({
    nameChangeCallback,
    passwordChangeCallback,
    keyPressCallback,
    triggerLogin
  }) => {
    return (
      <Padding padding={"0rem 2rem"}>
        <UserName callback={nameChangeCallback} />
        <Password
          callback={passwordChangeCallback}
          keyPressCallback={keyPressCallback} />
        <Submit
          button_text='LOG IN'
          callback={() => (triggerLogin())}
        />
      </Padding>
    )
  }
  return <LoginFormFunctionality Form={Styling} />
}

export function LoginForm({ }) {
  const Styling = ({
    nameChangeCallback,
    passwordChangeCallback,
    keyPressCallback,
    triggerLogin
  }) => {
    return (
      <BasicMenuAreaFormStyling>
        <FormInputsWithPositioning
          FormInputsWithPositioning="login"
          FormAction="post"
        >
          <UserName callback={nameChangeCallback} />
          <Password
            callback={passwordChangeCallback}
            keyPressCallback={keyPressCallback} />
          <Submit
            button_text='LOG IN'
            callback={() => (triggerLogin())}
          />
        </FormInputsWithPositioning>
      </BasicMenuAreaFormStyling>
    )
  }

  return <LoginFormFunctionality Form={Styling} />
}
