import { SelectVariableCheckbox } from "../SelectVariableCheckbox";
import { VMLOuter, VMLRight } from "./VMLinkStyling";

export function VariableLink({node, association, handleTextClick}) {
  return (
    <VMLOuter>
      <SelectVariableCheckbox 
        association={association}
        variable_id={node.id}
        scale={"1.2"}
      />
      <VMLRight onClick={handleTextClick}>
        {node.display_name}
      </VMLRight>
    </VMLOuter>
  )
}


