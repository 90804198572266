import { InputElementContainerSideBySidePositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementTimeline({ changeFunction }) {
  return (
    <InputElementContainerSideBySidePositioning
      label={"Estimated Timeline"}
    >
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction("methods_timeline");
          }
        }
      />
    </InputElementContainerSideBySidePositioning>
  )
}
