import { useEffect, useState } from "react";
import { InputElementContainerTopDownPositioning, InputElementSingleChoiceRadio, OptionalTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementSampleSize({ changeFunction }) {
  const [activeLabel, setActiveLabel] = useState("");

  useEffect(() => {
    changeFunction({ target: { value: activeLabel } }, "methods_sample_size");
  }, [activeLabel]);

  return <InputElementContainerTopDownPositioning label={"HCHS Cohort"}>
    <InputElementSingleChoiceRadio
      box_selected_callback={(label) => { setActiveLabel(label) }}
      label_array={[
        "First Data Release (10.000)",
        "Second Data Release (~16.400)",
        "post-COVID",
        "Exceptions",
      ]} />
    {
      //"10.000",
      //"17.000",
      activeLabel === "Exceptions" &&

      <OptionalTextArea
        placeholder={"Indicate Further Information"}
        onChangeCallback={(event) => { changeFunction(event, "methods_sample_size_exception") }} />

    }
  </InputElementContainerTopDownPositioning>;
}


