import { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "./text_display/main_content_text_styling";
//import { ReactHtmlParser } from "react-html-parser";

const BaseStyling = styled.div`
    position: relative;
    margin: 14px;

    & > div {
        background-color: #fff;
        border-style: solid;
        border-width: 1px;
        margin-top: 8px;
        margin-left: 6px;
        margin-right: 6px;
        padding: 8px;
        padding-top: 8px;
        border-radius: 3px;
        border-color: #1c79c1;
    }
`;
/*
async function get_publications_from_hchs_page(callback) {
    fetch(
        "/fetchablePHP/get_publication_content.php",
        {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
        }
    )
    .then((response) => response.text())
    .then((data) => callback(ReactHtmlParser(data)));
}*/

export function RecentPublications(props){
    const [publications, setPublications] = useState();

    useEffect(()=>{
        get_publications_from_hchs_page(setPublications);
        setPublications(<div>Mock Publications</div>)
    }, []);

    return (
        <>
            <Header text={"Recent Publications"}/>
            <BaseStyling>
                {publications}
            </BaseStyling>
        </>
    )
}


const PUBLICATION_URL = "http://hchs.hamburg/publikationen/";

async function get_publications_from_hchs_page(callback) {
    fetch(
        "/fetchablePHP/get_publication_content.php",
        {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
        }
    )
    .then((response) => response.text())
    .then((data) => callback(/*ReactHtmlParser(data)*/));
}

export function TempStaticPublicationSamples(){
    return (
        <div>
            <b>
                Fixel based analysis of white matter alterations in early stage
                cerebral small vessel disease
            </b>
            <br/>Autoren: <i>Marvin Petersen, Benedikt M. Frey, Carola Mayer, Simone Kühn, Jürgen Gallinat </i>
            <br/>Erscheinungsdatum: <i>18.01.2022</i>
            <br/>Link zur Veröffentlichung: <i><a href="http://doi.org/10.1038/s41598-022-05665-2">doi.org/10.1038/s41598-022-05665-2</a></i>
        </div>
    );
}
