import { InputElementContainerTopDownPositioning, InputItem } from "../../../text_inputs/main_content_inputs"

export function FormElementAffiliation({ changeFunction, iter }) {
  return (
    <InputElementContainerTopDownPositioning
      label={"Affiliation (UKE-Clinic /-Institute)"}
    >
      <InputItem iter={iter}>
        <input
          onChange={
            (event) => {
              changeFunction(event, "intro_affiliation")
            }
          }
          type="text"
          placeholder="Affiliation"
        />
        <div>
          TODO implement backend in handler
        </div>
      </InputItem>

    </InputElementContainerTopDownPositioning>
  )
}

