import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementBiomaterialsWhere({ changeFunction }) {
  return (
    <>
      <LabelSmall>
        Where Do The Measurements Take Place?
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "biomaterial_where");
          }
        }
      />
    </>
  )
}
