import styled from "styled-components";

export const VMGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  overflow: hidden;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100%;
`
