import { HeaderSmall } from "../../text_display/main_content_text_styling"
import { DownloadVariablesButton } from "./DownLoadVariablesButton"
import { DownloadIcon } from "../../icons/font_awesome_icons"
import { useEffect, useState } from "react"
import { useGraph } from "../../contexts/graph/provider"
import { VMLink } from "./VMLink/VMLink"
import { useProposalSelectionMapping } from "../../contexts/proposalsSelectionMapping/provider"
import { ScrollingListContainer } from "../variablemanua_main_content_display"
import { ColumnGrid } from "../../divider and frames/layouts"
import { OuterPanel } from "../panel_styling"

function SelectedVariablesList({ association }) {
  const [selectedVariables, setSelectedVariables] = useState(null);
  const { selectionManager, forceUpdateClock } = useProposalSelectionMapping();
  const { getNodeForId } = useGraph();

  const computeSelectedVariableLinks = () => {
    let selections = selectionManager.getSelectionsForProposalAndKind(association.proposal_id, association.kind);
    let newSelectedVariables = selections.map(
      (id, index) => {
        return (
          <VMLink key={id} association={association} node={getNodeForId(id)} />
        )
      }
    )
    setSelectedVariables(newSelectedVariables);
  }

  useEffect(() => { computeSelectedVariableLinks(); }, []);
  useEffect(() => { computeSelectedVariableLinks(); }, [forceUpdateClock])

  return (
    <ScrollingListContainer>
      {selectedVariables}
    </ScrollingListContainer>
  )
}

export function SelectedVariablesPanel({ association }) {

  return (
    <OuterPanel>
      <ColumnGrid template_columns={"1fr auto auto"}>
        <HeaderSmall text={"Selected Variables"} />
        <div>associate with proposal</div>
        <DownloadVariablesButton association={association}>
          <DownloadIcon />
        </DownloadVariablesButton>
      </ColumnGrid>
      <SelectedVariablesList association={association} />
    </OuterPanel>
  )
}
