import { createContext, useContext, useState } from "react";
import { VariableSelectionManager } from "typescript_helpers";

const ProposalSelectionMappingContext = createContext();

export const SelectionMappingProvider = ({ children }) => {
  const [selection_manager, set_selection_manager] = useState(new VariableSelectionManager());
  const [forceUpdateClock, setForceUpdateClock] = useState(true);

  const setMan = (newManager) => {
    set_selection_manager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const ContextValues = {
    selectionManager: selection_manager,
    setSelectionManager: setMan,
    forceUpdateClock: forceUpdateClock
  }


  return (
    <ProposalSelectionMappingContext.Provider value={ContextValues}>
      {children}
    </ProposalSelectionMappingContext.Provider>
  )
}

export const useProposalSelectionMapping = () => {
  return useContext(ProposalSelectionMappingContext);
}
