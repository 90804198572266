import { createContext, useContext, useState } from "react";
import { CurrentNodeManager } from "typescript_helpers";

const CurrentNodesContext = createContext();

export const CurrentNodesProvider = ({ children }) => {
  const [
    nodesManager,
    setNodesManager
  ] = useState(new CurrentNodeManager());

  const [forceUpdateClock, setForceUpdateClock] = useState(true);

  const setMan = (newManager) => {
    setNodesManager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const ContextValues = {
    nodesManager: nodesManager,
    setNodesManager: setMan,
    forceUpdateClock: forceUpdateClock
  }

  return (
    <CurrentNodesContext.Provider value={ContextValues}>
      {children}
    </CurrentNodesContext.Provider>
  )
}

export const useCurrentNodes = () => {
  return useContext(CurrentNodesContext);
}
