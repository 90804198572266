import { useGraph } from "../../../../contexts/graph/provider";
import { HistoryEntry } from "./HistoryEntry";

export function HistoryEntries({ association, history_ids }) {
  const baseIndentWidth = 1;
  const baseIndentUnit = "rem";
  const { getNodeForId } = useGraph();

  const dropLeadingElements = (lst, element) => {
    const firstNonTargetIndex = lst.findIndex(item => item !== element);
    return firstNonTargetIndex === -1 ? [] : lst.slice(firstNonTargetIndex);
  }

  const filterEntries = (history_ids) => {
    history_ids = history_ids.filter((id) => id !== undefined);
    history_ids = dropLeadingElements(history_ids, "Start / Overview");
    return history_ids;
  }

  return <>
    {
      filterEntries(history_ids).map((id, index) => {
        if (id === undefined) return <></>;
        return (
          <HistoryEntry
            key={index}
            indentSpecification={{ baseIndentUnit: baseIndentUnit, baseIndentWidth: baseIndentWidth, indentFactor: index }}
            node={getNodeForId(id)}
            is_current_entry={index === (history_ids.length - 1)}
            association={association}
          />
        )
      })
    }
  </>
}
