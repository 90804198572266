import { useNavigate } from "react-router";
import { Intention } from "../../nonComponentFunctions/Intention";
import { useEffect, useState } from "react";
import { UKEDividerBar } from "../divider and frames/divider_bars";
import { Header } from "../text_display/main_content_text_styling";
import { NoteIntentionSubmitModal } from "./intention_submit_modal";
import {
  InputElementButtonContainer,
} from "../text_inputs/main_content_inputs";
import { Submit } from "../buttons/menu_area_buttons";
import { FormElementIntroSection } from "./common_form_elemnts/FormElementIntroSection";
import { FormElementMethodology } from "./common_form_elemnts/FormElementMethodology";
import { FormSectionAnalysisPlan } from "./common_form_elemnts/analysis/AnalysisPlan";
import { FormElementBiomaterials } from "./common_form_elemnts/biomaterials/FormElementsBiomaterials";
import { FormSectionFurtherInformation } from "./FormSectionFurtherInformation";

export function NoteIntentionFromDev() {
  const [iter, setIter] = useState(0);
  const [form, setForm] = useState(null);
  const resetForm = () => {
    setIter(iter + 1);
  }

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    setForm(<NoteIntentionForm iter={iter + 1} navigate={navigate}
      resetCallback={resetForm} />);
  }, [iter]);

  const navigate = useNavigate();

  return (<>
    {form}
  </>)
}

function NoteIntentionForm({ iter, resetCallback }) {
  const [modal_show, set_modal_show] = useState(false);
  const [intention, setIntention] = useState(new Intention());

  const submit_callback = () => {
    set_modal_show(true);
  }
  const update_intention = (attribute_id, val) => {
    intention.setIntentionAttribute(attribute_id, val);
  }

  const changeFunction = (event, attribute_id) => {
    update_intention(attribute_id, event.target.value);
  }

  return <>
    {
      modal_show &&
      <NoteIntentionSubmitModal
        iter={iter}
        intention={intention}
        closeModalCallback={
          () => {
            resetCallback();
            set_modal_show(false)
          }
        }
      />
    }
    <Header text={"Issue Announcement"} />
    <div>
    <FormElementIntroSection
      changeFunction={changeFunction}
      iter={iter}
    />
    <UKEDividerBar />
    <FormElementMethodology changeFunction={changeFunction} />
    <UKEDividerBar />
    <FormSectionAnalysisPlan
      changeFunction={changeFunction}
      intention={intention} />
    <UKEDividerBar />
    <FormElementBiomaterials changeFunction={changeFunction} />
    <UKEDividerBar />
    <FormSectionFurtherInformation changeFunction={changeFunction} />
    <InputElementButtonContainer>
      <Submit button_text={"Submit"} callback={submit_callback} />
    </InputElementButtonContainer>
    </div>
  </>
}
