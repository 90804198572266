
import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementBiomaterialsNumber({ changeFunction }) {
  return (
    <>
      <LabelSmall>
        Total Number / Subject Specification
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "biomaterial_number");
          }
        }
      />
    </>
  )
}
