import { useEffect, useState } from "react";
import { LabelSmall, OptionalInputIndent } from "../../../../text_display/main_content_text_styling";
import { InputElementCheckbox, InputItem } from "../../../../text_inputs/main_content_inputs";

export function FormElementMRISubSelectionTarget({ changeFunction }) {
  const box1_label = "cardio";
  const box2_label = "neuro";

  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  useEffect(() => {
    changeFunction({ target: { value: box1_selected } }, "methods_setting_mri_cardio");
  }, [box1_selected])

  useEffect(() => {
    changeFunction({ target: { value: box2_selected } }, "methods_setting_mri_neuro");
  }, [box2_selected])

  return (
    <OptionalInputIndent>
      <LabelSmall>Select kind of MRI examination</LabelSmall>
      <InputItem>
        <InputElementCheckbox
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox1_selected(!box1_selected);
          }}
        />
        <InputElementCheckbox
          label={box2_label}
          checked={box2_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox2_selected(!box2_selected);
          }}
        />
      </InputItem>
    </OptionalInputIndent>
  )
}


