import { NoticeText } from "../../text_display/main_content_text_styling";
import { InputElementContainerSideBySidePositioning, InputElementContainerTopDownPositioning, InputElementMultilineTextArea, InputItem } from "../../text_inputs/main_content_inputs";
import { FormElementAuthorDetails } from "./FormElementAuthorDetails";
import { FormHeader } from "./FormHeader";
import { FormElementAffiliation } from "./intro/affiliation";
import { FormElementHypothesis } from "./intro/hypothesis";
import { FormElementOverlap } from "./intro/overlap";
import { FormElementRationale } from "./intro/rational";

export function FormElementIntroSection({changeFunction, mode, iter, role}) {
  return (
    <>
      <FormHeader>Introduction</FormHeader>
      <InputElementContainerSideBySidePositioning
        label={"Project Title"}>
        <InputItem iter={iter}>
          <input
            onChange={
              (event) => {
                changeFunction(event, "intro_project_title");
              }
            }
            type="text"
            placeholder="Project Title" />
        </InputItem>
      </InputElementContainerSideBySidePositioning>
      <FormElementAuthorDetails
        changeFunction={changeFunction}
        mode={mode}
        iter={iter}
        role={role}
      />
      <FormElementAffiliation
        changeFunction={changeFunction}
        iter={iter}
      />
      <FormElementRationale
        changeFunction={changeFunction}
      />
      <FormElementHypothesis
        changeFunction={changeFunction}
      />
      <FormElementOverlap
        changeFunction={changeFunction}
      />
      {/*
      <InputElementContainerSideBySidePositioning
        label={"Novelty of the project"}
      >
        <InputElementMultilineTextArea
          onChange={
            (event) => {
              changeFunction(event, "intro_novelty");
            }
          }
        />
      </InputElementContainerSideBySidePositioning>
      */}
    </>
  )
}
