import { useEffect, useState } from "react";
import { useGraph } from "../../contexts/graph/provider";
import { LinkPanelStyling, OuterPanel } from "../panel_styling";
import { HeaderSmall, HeaderTiny, Padding } from "../../text_display/main_content_text_styling";
import { RedDividerBar, UKEDividerBar } from "../../divider and frames/divider_bars";
import { ScrollingListContainer } from "../../divider and frames/layouts";
import { HistoryEntry } from "./VMLink/VMHistory/HistoryEntry";
import { useVMHistories } from "../../contexts/VMHistories/provider";
import { HistoryEntries } from "./VMLink/VMHistory/HistoryEntries";

export function VMHistoryPanel({ association }) {

  const [entries, setEntries] = useState(<></>);
  const { forceUpdateClock, getLastHistoryElements } = useVMHistories();
  const { getNodeForId } = useGraph();

  const updateHistoryEntries = () => {
    const history_ids = getLastHistoryElements(association.proposal_id, association.kind, 5);
    console.log(`in updateHistoryEntries history_ids are ${JSON.stringify(history_ids)} for association ${JSON.stringify(association)}`)
    setEntries(
      <HistoryEntries
        association={association}
        history_ids={history_ids}
      />
    )
  }

  useEffect(() => { updateHistoryEntries() }, [forceUpdateClock]);

  return (
    <OuterPanel>
      <HeaderSmall text={"Last Seen"} />
      <div style={{ margin: "0rem 0rem 1rem 0rem" }}>
        <HistoryEntry
          node={getNodeForId("Start / Overview")}
          association={association}
          is_current_entry={false}
        />
      </div>
      <UKEDividerBar />
      <Padding padding={"5px 0px"}/>
      {entries}
    </OuterPanel>
  )
}
