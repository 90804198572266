import styled from "styled-components";
import { BorderSolidSlimBlack } from "../../themes and styles/borders";
import { ThemeColor } from "../../themes and styles/colors";
import { useProposalSelectionMapping } from "../../contexts/proposalsSelectionMapping/provider";
import { useGraph } from "../../contexts/graph/provider";
import { isList } from "typescript_helpers";
import { VariablesCSVExporter } from "../../../nonComponentFunctions/pdf_generation/variable_exporting";

const DownloadVariablesButtonStyling = styled.div`
  ${BorderSolidSlimBlack};
  padding: .3rem;
  place-self: center;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`

export function DownloadVariablesButton({ association, children }) {
  const { variablesManager } = useProposalSelectionMapping();
  const { getNodeForId } = useGraph();

  const downloadCSV = () => {
    console.log("clicked button")
    console.log(`association is ${association.proposal_id} ${association.kind}`)
    let selected_ids = variablesManager.getSelectionsForProposalAndKind(association.kind, association.proposal_id);
    console.log(`selected_ids are ${selected_ids}`)
    let variable_nodes = selected_ids.map(getNodeForId);
    console.log(`variable_nodes are ${variable_nodes}`)
    if (!isList(variable_nodes) || variable_nodes.length === 0) return;
    console.log(`post guard`)
    let exporter = new VariablesCSVExporter(variable_nodes);
    exporter.loopVariablesAddAttributesToTable(exporter.variables_attributes_by_name);
    //const table_content = exporter.exportTableToCSV();
    //let export_blob = new Blob([table_content], { type: 'text/csv;charset=utf-8' });
    //let url = URL.createObjectURL(export_blob);
    //let downloadElement = document.createElement('a');
    //downloadElement.href = url;
    //downloadElement.setAttribute('download', 'variable_selection.csv');
    //downloadElement.click();
  }

  return <DownloadVariablesButtonStyling onClick={()=>{console.log("click");downloadCSV()}}>
    {children}
  </DownloadVariablesButtonStyling>
}
