import { 
    MenuAreaButtonIconLeft as IconLeftButton,
    MenuAreaButtonIconRight as IconRightButton,
    SingleIconWidgetButton
} from "./button_styling";
import { usePageLayout } from "../page_layouts";
import { 
    ToggleUpIcon, 
    ToggleDownIcon,
    LessThanIcon, 
    GreaterThanIcon,
    DownloadIcon} from "../icons/font_awesome_icons";
import { Proposal } from "../../nonComponentFunctions/Proposal";
import { UKE_PDF } from "../../nonComponentFunctions/pdf_generation/pdf_generation";
import { proposal_structure } from "../../proposal_structuring";
import { Intention } from "../../nonComponentFunctions/Intention";

export function CollapseMenuArea(props){
    const { toggleMenuCollapse } = usePageLayout();
    return (
        <SingleIconWidgetButton onClick={
            (event) => {toggleMenuCollapse();}}>
            <ToggleUpIcon/>
        </SingleIconWidgetButton>
    )
}

export function UncollapseMenuArea(props){
    const { toggleMenuCollapse } = usePageLayout();
    return (
        <SingleIconWidgetButton onClick={
            (event) => {toggleMenuCollapse();}}>
            <ToggleDownIcon/>
        </SingleIconWidgetButton>
    )
}

export function NavigateBack(props){
    return (
        <IconLeftButton 
            button_text="back" 
            icon={<LessThanIcon/>}
            callback={props.callback}
            {...props}
        />
    )
}

export function Submit(props){
    return (
        <IconRightButton
            button_text={props.button_text}
            icon={<GreaterThanIcon/>}
            callback={props.callback}
            {...props}
        />
    )
}

export function DownloadProposalButton({proposal, intention}){
  const downloadProposalCallback = () => {
    if (!(proposal instanceof Proposal) && !(intention instanceof Intention) || (proposal === null && intention === null)) return;
    console.log("before generation of pdf");
    let pdf = new UKE_PDF("pdf_name", [proposal_structure], proposal);
    console.log("after pdf generation");
    pdf.download();
  } 

  if (!(proposal instanceof Proposal) && !(intention instanceof Intention)) return null;
  return (
    <IconLeftButton 
      width={"10rem"}
      callback={downloadProposalCallback}
      icon={<DownloadIcon />}
      button_text={"Download Proposal"}/>
  )
}

