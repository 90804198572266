import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea, InputElementSingleChoiceRadio } from "../../../text_inputs/main_content_inputs";

export function FormElementCoorperationPartners(
  { changeFunction,
    hasPartners,
    updateHasPartners
  }) {
  return (
    <InputElementContainerTopDownPositioning
      label={"Internal / External Cooperation Partners"}
    >
      <InputElementSingleChoiceRadio
        box_selected_callback={
          (id) => {
            updateHasPartners(id);
          }
        }
        label_array={["Yes", "No"]}
      />
      {
        (hasPartners &&
          <InputElementMultilineTextArea
            onChange={
              (event) => {
                changeFunction(event, "other_partners");
              }
            }
          />
        )
      }
    </InputElementContainerTopDownPositioning>
  )
}
