
import { InputElementContainerSideBySidePositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs"

export function FormElementHypothesis({ changeFunction }) {
  return (
    <InputElementContainerSideBySidePositioning 
      label={"Objective(s) / Hypothesis"}>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "intro_objectives");
          }
        }/>
    </InputElementContainerSideBySidePositioning>
  )
}
