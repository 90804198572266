import { InputElementContainerSideBySidePositioning, InputItem } from "../../text_inputs/main_content_inputs";

export function FormElementAuthorDetails({ changeFunction, mode, iter, role }) {
  return (
    <InputElementContainerSideBySidePositioning
      iter={iter}
      label={"Applicant / Coordination Investigator"}>
      <InputItem>
        <input
          onChange={
            (event) => {
              changeFunction(event, "intro_applicant_firstname");
            }
          }
          type="text"
          placeholder="first name"
        />
      </InputItem>
      <InputItem>
        <input
          onChange={
            (event) => {
              changeFunction(event, "intro_applicant_lastname");
            }
          }
          type="text"
          placeholder="last name"
        />
      </InputItem>
      {
        (role === 'admin' || mode === 'nachtragen') && (
          <InputItem>
            <input
              onChange={
                (event) => {
                  changeFunction(event, "USER");
                }
              }
              type="text"
              placeholder="email"
            />
          </InputItem>
        )
      }
    </InputElementContainerSideBySidePositioning>
  )
}
