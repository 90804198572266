import styled from 'styled-components';

export const StyledTextInput = styled.input`
    border: none;
    border-bottom: 2px solid #d1d1d4;
    border-radius: 4px;
    background: none;
    padding: 10px;
    padding-left: 24px;

    font-weight: 700;
    width: 100%;
    transition: 0.2s;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        outline: none;
        border-bottom-color: #6a679e;
    }
`;

export const TextLine = styled.div`
    padding: 20px 0px;
    position: relative;
`;

export const AdjustLeft = styled.span`
    position: absolute;
    top: 29px;
    color: #7875b5;
`;

export function TextInputWithIconLeft(props) {
  return (
    <TextLine>
      <AdjustLeft>
        {props.icon}
      </AdjustLeft>
      <StyledTextInput
        onChange={
          (event) => {
            props.changeCallback(event.target.value)
          }
        }
        onKeyPress={props.keyPressCallback}
        type={props.isVisible ? 'text' : 'password'}
        placeholder={props.placeholder_text}
        {...props}
      />
    </TextLine>
  )
}
