import { createContext, useContext, useState } from "react";
import { useGraph } from "../graph/provider";

const CurrentNodeContext = createContext();

export const CurrentNodeProvider = ({ children }) => {
  const graphContext = useGraph();

  const [currentID, newCurrentID] = useState("");
  const [currentDetails, newCurrentDetails] = useState([]);

  const setID = (id, token, mail) => {
    let node = graphContext.getNodeForId(id, token, mail);
    newCurrentID(node.id);
    newCurrentDetails(node.details);
  }

  const ContextValues = {
    setID: setID,
    currentID: currentID,
    currentDetails: currentDetails
  }

  return (
    <CurrentNodeContext.Provider value={ContextValues}>
      {children}
    </CurrentNodeContext.Provider>
  )
}

export function useCurrentNode() {
  return useContext(CurrentNodeContext);
}
