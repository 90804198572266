import { FormHeader } from "../FormHeader";
import { FormElementPlanedAnalysis } from "./analysis_details";
import { FormElementCohortDefinition } from "./cohort";
import { FormElementNeededData } from "./FormElementNeededData";
import { FormElementMissingData } from "./missing_data";
import { FormElementTypeOfPublication } from "./type_of_publication";

export function FormSectionAnalysisPlan({ changeFunction, proposal, intention, mode }) {
  const id = (
    (
      (mode === "preselected_variables")
      &&
      null
    )
    || (
      proposal !== undefined &&
      proposal.getProposalAttribute('id')
    )
    || (
      intention !== undefined &&
      intention.getIntentionAttribute('id')
    )
    || null
  )
  return (
    <>
      <FormHeader>Plan of Analysis</FormHeader>
      {FormElementCohortDefinition(changeFunction)}
      <FormElementNeededData
        changeFunction={changeFunction}
        proposal_id={id} />
      <FormElementMissingData changeFunction={changeFunction} />
      <FormElementPlanedAnalysis changeFunction={changeFunction} />
      <FormElementTypeOfPublication changeFunction={changeFunction} />
    </>
  )
}
