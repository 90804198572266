import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavigateBack, Submit } from "../buttons/menu_area_buttons";
import { BasicMenuAreaFormStyling, FormInputsWithPositioning, HeaderInputsWithPositioning } from "./form_layouts";
import { HideExceptAdmin, HideExceptAuth } from "../../authentication/require_auth";
import { useAuth } from "../../authentication/auth_provider";
import { usePageLayout } from "../page_layouts";
import { VariableProposalMapper } from "../variablenmanual/variable_sorter";
import { variablemanual_standalone_association_value } from "../variablenmanual/variablemanual";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import { ToggleDownIcon } from "typescript_helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowDown } from "../icons/font_awesome_icons";
import { ThemeColor } from "../themes and styles/colors";

function NavEntry({ Wrapper, text, nav_target, nav_callback, permission, styleAtLocation }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [atLocation, setAtLocation] = useState(false);

  useEffect(() => {
    setAtLocation(location.pathname.startsWith(nav_target));
  }, [location.pathname, nav_target])
  const clickHandler = (e) => {
    if (nav_target !== undefined) {
      navigate(nav_target);
    }
    if (nav_callback !== undefined) {
      nav_callback();
    }
  }

  const getStyle = (shouldStyle) => {
    if (shouldStyle) {
      return {
        color: ThemeColor.uke.blue.light,
        cursor: "pointer"
      }
    }
    return {
        cursor: "pointer"
    }
  }

  let entry = <span onClick={clickHandler}>
    <Wrapper style={getStyle(atLocation && styleAtLocation)}>{text}</Wrapper>
  </span>

  if (permission === "all" || permission === undefined) return entry;
  if (permission === "auth") return (
    <HideExceptAuth>
      {entry}
    </HideExceptAuth>
  );
  if (permission === "admin") return (
    <HideExceptAdmin>
      {entry}
    </HideExceptAdmin>
  );

}

const StyledDowndownToggle = styled.button`
  all: unset;
  margin: 2px;
  border: "none";

  &:after {
    all: unset;
  }
`

function NavEntries({ Wrapper, main_title, main_target, main_permission, title_target_list }) {
  const [show, setShow] = useState(false);
  const timeoutRef = useRef(null);
  const timeout_in_ms = 100;
  const location = useLocation();
  const [atLocation, setAtLocation] = useState(false);
  const { role, logged_in } = useAuth();

  useEffect(() => {
    setAtLocation(location.pathname.startsWith(main_target));
  }, [location.pathname, main_target])

  const HeaderWrapper = ({ children }) => {
    return <span style={
      {
        color: (atLocation && ThemeColor.uke.blue.light) || ThemeColor.uke.font.nav.header,
        fontWeight: "bold",
        cursor: "pointer"
      }
    }>{children}</span>
  }
  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShow(true);
  }

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(false);
    }, timeout_in_ms);
  }

  const filter_entries = (title_target_permission_list, userRole, logged_in) => {
    if (userRole === "admin") return title_target_permission_list;
    let filtered_list = [];
    for (let title_target_permission in title_target_permission_list) {
      if (title_target_permission.length === 2) {
        filtered_list.append(title_target_permission);
      }
      else {
        let permission = title_target_permission[2];
        if (permission === "auth" && logged_in) {
          filtered_list.append(title_target_permission);
        }
        if (permission === "admin" && userRole === "admin") {
          filtered_list.append(title_target_permission);
        }
      }
    }
  }

  const filtered_entries = filter_entries(title_target_list, role, logged_in);

  if (
    (main_permission === "auth" && !logged_in) || (main_permission === "admin" && role !== "admin")
  ) {
    return <></>;
  }

  if (!Array.isArray(filtered_entries) || !filtered_entries.length) {
    return (
      <NavEntry
        Wrapper={HeaderWrapper}
        text={main_title}
        nav_target={main_target}
      />
    )
  }
  return (
    <Dropdown
      as={ButtonGroup}
      style={
        {
          "alignItems": "baseline",
          "gap": "0.5rem",
          "cursor": "grabbing"
        }
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show}
    >
      <NavEntry
        Wrapper={HeaderWrapper}
        text={main_title}
        nav_target={main_target}
      />
      <Dropdown.Toggle as={StyledDowndownToggle} split id="dropdown-split-basic">
        <ArrowDown style={{ color: ThemeColor.uke.font.nav.header }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          title_target_list.map((entry) => {
            return (
              <Dropdown.Item style={{ width: "auto" }}>
                <NavEntry
                  key={entry[1]}
                  Wrapper={Wrapper}
                  text={entry[0]}
                  nav_target={entry[1]}
                  permission={entry[2]}
                  styleAtLocation={true}
                />
              </Dropdown.Item>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

function PublicationNav({ Wrapper }) {
  return (
    <NavEntries
      Wrapper={Wrapper}
      main_title="Publications"
      main_target="/publications"
      title_target_list={[]}
    />
  )
}

function VariableManualNav({ Wrapper }) {
  return (
    <NavEntries
      Wrapper={Wrapper}
      main_title="Variable Manual"
      main_target="/variablemanual"
      main_permission={"auth"}
      title_target_list={[]}
    />
  )
}

function ProposalNav({ Wrapper }) {
  const title_target_list = [
    ["Proposal Overview", "/proposals/overview", "auth"],
    ["Issue Proposal", "/proposals/issueProposal", "auth"],
    ["Issue Announcement", "/proposals/noteIntention", "auth"],
    ["Add missing Proposal", "/proposals/antrag_nachtragen", "admin"]
  ];
  return (
    <NavEntries
      Wrapper={Wrapper}
      main_title="Proposals"
      main_target="/proposals"
      main_permission={"auth"}
      title_target_list={title_target_list}
    />
  )
}

function AccountVerwaltungNav({ Wrapper }) {
  const title_target_list = [
    ["Change Password", "/account/changePassword", "auth"],
    ["Set Password for User", "/account/setUserPassword", "admin"],
    ["Add new User", "/account/addNewUser", "admin"]
  ]
  return (
    <NavEntries
      Wrapper={Wrapper}
      main_title="Account Management"
      main_permission={"auth"}
      main_target="/account"
      title_target_list={title_target_list}
    />
  )
}

function FAQNav({ Wrapper }) {
  return (
    <NavEntries
      Wrapper={Wrapper}
      main_title="FAQ"
      main_target="/faq"
      title_target_list={[]}
    />
  )
}

export function NavFormTop(props) {
  const Wrapper = ({ children, style }) => {
    return <span style={style}>{children}</span>
  }

  return (
    <HeaderInputsWithPositioning>
      <AccountVerwaltungNav Wrapper={Wrapper} />
      <ProposalNav Wrapper={Wrapper} />
      <VariableManualNav Wrapper={Wrapper} />
      <PublicationNav Wrapper={Wrapper} />
      <FAQNav Wrapper={Wrapper} />
    </HeaderInputsWithPositioning>
  )

}

//migrated
export function NavForm(props) {
  const { role } = useAuth();
  const navigate = useNavigate();
  const { activateOverlayModal } = usePageLayout();

  const accountVerwaltungInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
      <Submit button_text="Change Password" callback={() => { navigate("/account/changePassword") }} />
      <HideExceptAdmin>
        <Submit button_text="Set Password for user" callback={() => { navigate("/account/setUserPassword") }} />
      </HideExceptAdmin>
      <HideExceptAdmin>
        <Submit button_text="Add new user" callback={() => { navigate("/account/addNewUser") }} />
      </HideExceptAdmin>
    </FormInputsWithPositioning>

  const anträgeVorhabenInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
      <Submit callback={() => { navigate("/proposals/issueProposal") }} button_text="Issue Proposal" />
      <Submit callback={() => { navigate("/proposals/noteIntention") }} button_text="Note Intention" />
      <Submit callback={() => { navigate("/proposals/overview") }} button_text="Proposal Overview" />
      <HideExceptAdmin>
        <Submit callback={() => { navigate("/proposals/antrag_nachtragen") }} button_text="Add missing Proposal" />
      </HideExceptAdmin>
    </FormInputsWithPositioning>

  const initialInputs = <FormInputsWithPositioning>
    <Submit callback={() => { updateInputs(accountVerwaltungInputs); navigate("/account") }} button_text="Account Management" />
    <Submit callback={() => { updateInputs(anträgeVorhabenInputs); navigate("/proposals") }} button_text="Proposals" />
    <Submit button_text="Variable Manual" callback={() => { updateInputs(variableManualInputs); navigate("/variablemanual") }} />
    <Submit button_text="Publications" callback={() => { updateInputs(publikationenInputs); navigate("/publications") }} />
  </FormInputsWithPositioning>

  const publikationenInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
    </FormInputsWithPositioning>

  const variableManualInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs); }} />
      {/*<Submit button_text="Export Selection"/>*/}
      <Submit button_text="Associate with proposal"
        callback={() => { activateOverlayModal(<VariableProposalMapper association={variablemanual_standalone_association_value} />) }} />
    </FormInputsWithPositioning>

  const [inputs, updateInputs] = useState(initialInputs);

  return (
    <BasicMenuAreaFormStyling>
      {inputs}
    </BasicMenuAreaFormStyling>
  )
}
