import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementAuthorList({ changeFunction }) {
  return (
    <InputElementContainerTopDownPositioning
      label={"Preliminary List Of Authors For Publication"}
    >
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "other_manuscript")
          }
        }
      />
    </InputElementContainerTopDownPositioning>
  )
}
