import { VMLOuter, VMLRight } from "./VMLinkStyling"

export function ContextLink({ displayName, handleTextClick, $background }) {
  return (
    <VMLOuter $template_columns={"1fr"}>
      <VMLRight
        onClick={handleTextClick}
        background={$background}
      >
        {displayName}
      </VMLRight>
    </VMLOuter>
  )
}
