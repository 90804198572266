import { createContext, useContext, useEffect, useState } from "react";
import { useCurrentNode } from "../currentNode/provider";

const VariableHistoryContext = createContext();

export const VariableHistoryProvider = ({ children }) => 
{
  const currentVarContext = useCurrentNode();
  const [history, setNewHistory] = useState([]);

  const handleCurrentIDChange = (id) => {
    history.push(id);
    setNewHistory(history);
  }

  const getLastHistoryElements = (numberElements) => {
    return history.slice(-numberElements);
  }

  const jumpBackInHistory = (id_to_jump_back) => {
    let history_copy = structuredClone(history);
    let last_element = null;
    while (history_copy.length !== 0 && last_element !== id_to_jump_back) {
      last_element = history_copy.pop();
    }
    if (last_element === id_to_jump_back) {
      setNewHistory(history_copy);
      currentVarContext.setID(id_to_jump_back);
    }
  }

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID);
  }, [currentVarContext])

  const ContextValues = {
    getLastHistoryElements: getLastHistoryElements,
    jumpBackTo: jumpBackInHistory,
  }

  return (
    <VariableHistoryContext.Provider value={ContextValues}>
      {children}
    </VariableHistoryContext.Provider>
  )
}

export function useVariableHistory() {
  return useContext(VariableHistoryContext);
}
