import { InputElementContainerSideBySidePositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementOverlap({ changeFunction }) {
  return (
    <InputElementContainerSideBySidePositioning
      label={"Known Content Overlap With Other Proposals?"}
    >
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "intro_overlap");
          }
        }
      />
    </InputElementContainerSideBySidePositioning>
  )
}
