import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../authentication/auth_provider";
import { Proposal } from "../../nonComponentFunctions/Proposal";
import { Submit } from "../buttons/menu_area_buttons";
import { UKEDividerBar } from "../divider and frames/divider_bars";
import { Header, LabelSmall, OptionalInputIndent } from "../text_display/main_content_text_styling";
import {
  InputElementButtonContainer,
  InputElementCheckbox,
  InputItem,
} from "../text_inputs/main_content_inputs";
import { ProposalSubmitModal } from "./proposal_submit_modal";
import { FormElementIntroSection } from "./common_form_elemnts/FormElementIntroSection";
import { FormElementMethodology } from "./common_form_elemnts/FormElementMethodology";
import { FormSectionAnalysisPlan } from "./common_form_elemnts/analysis/AnalysisPlan";
import { FormElementBiomaterials } from "./common_form_elemnts/biomaterials/FormElementsBiomaterials";
import { FormSectionFurtherInformation } from "./FormSectionFurtherInformation";

export function IssueProposalFormDev({ mode }) {

  const [iter, setIter] = useState(0);
  const [form, setForm] = useState(null);
  const resetForm = () => {
    setIter(iter + 1);
  }

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    setForm(<ProposalForm iter={iter + 1} mode={mode} navigate={navigate} resetCallback={resetForm} />);
  }, [iter]);

  const navigate = useNavigate();
  return (
    <>
      {form}
    </>
  )
}

function ProposalForm({ iter, mode, resetCallback }) {
  const [modal_show, set_modal_show] = useState(false);
  const [proposal, setProposal] = useState(new Proposal());
  const { role } = useAuth();

  const submit_callback = () => {
    set_modal_show(true);
  }
  const update_proposal = (attribute_id, val) => {
    proposal.setProposalAttribute(attribute_id, val);
  }
  const changeFunction = (event, attribute_id) => {
    update_proposal(attribute_id, event.target.value);
  }

  return <>
    {modal_show &&
      <ProposalSubmitModal
        iter={iter}
        proposal={proposal}
        closeModalCallback={() => { resetCallback(); set_modal_show(false) }}
        mode={mode} />}
    <Header text={(mode === "nachtragen" && "Add Missing Proposal") || "Issue Proposal"} />
    <div>
    <FormElementIntroSection
      changeFunction={changeFunction}
      mode={mode}
      iter={iter}
      role={role}
    />
    <UKEDividerBar />
    <FormElementMethodology changeFunction={changeFunction} />
    <UKEDividerBar />
    <FormSectionAnalysisPlan
      changeFunction={changeFunction}
      proposal={proposal}
      mode={mode}
    />
    <UKEDividerBar />
    <FormElementBiomaterials changeFunction={changeFunction} />
    <UKEDividerBar />
    <FormSectionFurtherInformation changeFunction={changeFunction} />
    <InputElementButtonContainer>
      <Submit button_text={"Submit"} callback={submit_callback} />
    </InputElementButtonContainer>
    </div>
  </>
}

function FormElementStudyDesignSubSelection({ changeFunction }) {
  const box1_label = "Personal Follow-up";
  const box2_label = "Revisit Questionnaire";

  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  const callCallbackWithDefinedValues = () => {
    let val = ""
    if (box1_selected) val += box1_label;
    if (box2_selected) val += "; " + box2_label;
    changeFunction({ target: { value: val } }, "methods_design_prosp");
  }

  useEffect(() => {
    callCallbackWithDefinedValues();
  }, [box1_selected, box2_selected])

  return (
    <OptionalInputIndent>
      <LabelSmall>Select Kind Of Follow-Up</LabelSmall>
      <InputItem>
        <InputElementCheckbox
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox1_selected(!box1_selected);
          }}
        />
        <InputElementCheckbox
          label={box2_label}
          checked={box2_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox2_selected(!box2_selected);
          }}
        />
      </InputItem>
    </OptionalInputIndent>
  )
}

