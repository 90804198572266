import { isOneOf } from "typescript_helpers";
import { useAuth } from "../../../authentication/auth_provider";
import { VMLink } from "./VMLink/VMLink";
import { LinkPanelStyling, OuterPanel } from "../panel_styling";
import { ColumnGrid } from "../../divider and frames/layouts";
import { HeaderSmall, HeaderTiny } from "../../text_display/main_content_text_styling";
import { ScrollingListContainer } from "../variablemanua_main_content_display";
import { InfoMenu } from "../../widgets/inlo_options_menu";


export function VMLinkPanel({ headerText, nodes, tooltip_text, association }) {

  const { isAdmin } = useAuth();
  const renderVMLink = (node, index) => {
    if (!isAdmin && isOneOf(node.visibility, ["internal", "hidden", "intern"])) {
      return null;
    }
    return (
      <VMLink
        key={`${index} ${node.id}`}
        association={association}
        node={node}
      />
    )
  }

  return (
    <OuterPanel>
      <ColumnGrid template_columns={"1fr auto"}>
        <HeaderSmall text={headerText} />
{/*
        <InfoMenu tooltip_text={tooltip_text} />
        */}
      </ColumnGrid>
      <ScrollingListContainer>
        {nodes.map((node, index) => renderVMLink(node, index))}
      </ScrollingListContainer>
    </OuterPanel>
  )
}
