import { createContext, useContext, useState } from "react";
import { Graph, Node } from "typescript_helpers";
import { useAuth } from "../../../authentication/auth_provider";

const GraphContext = createContext();

export const GraphProvider = ({ children, graph }) => {
  const {token, mail} = useAuth();
  const [referenceGraph, setReferenceGraph] = useState(
    new Graph(
      graph.nodes,
      graph.links,
      ["Root"]));
  const getNodesLinkingToID = (id) => {
    let nodes = referenceGraph.getLinkedToNodes(id);
    return nodes;
  }

  window["graph"] = referenceGraph;

  const executeNodeSearch = (searchString) => {
    console.log(`referenceGraph on executing search is ${typeof referenceGraph}`)
    console.log(`referenceGraph ${JSON.stringify(Object.keys(referenceGraph))}`)
    let res_graph = referenceGraph.addSearchNode(searchString);
    let nodeID_search_result = `Search Term: ${searchString}`;
    res_graph.addNewNode("meta_node: Searches", [
      {
        attributeName: "Description",
        attributeContent: "This Context links to all previously made searches.",
        visibility: "visible"
      }
    ], "visible", "context", "User Searches");
    res_graph = res_graph.addNewLink("meta_node: Searches", nodeID_search_result);
    setReferenceGraph(res_graph);
    return nodeID_search_result;
  }

  const getNodesLinkedFromID = (id) => {
    console.warn("getNodesLinkingFromID")
    let nodes = referenceGraph.getLinkedFromNodes(id);
    return nodes;
  }

  const getGraph = () => { return referenceGraph; };

  const getNodeForId = (id) => {
    console.warn("getNodeForId")
    return referenceGraph.getNodeForId(id, token, mail);
  }
/*
  const filterForContexts = (nodes) => {
    console.warn("filterForContexts")
    let result = {};
    for (const node of nodes) {
      if (Node.isVMContext(node)) {
        result[node.id] = node;
      }
    }
    return Object.values(result);
  };
*/
/*
  const filterForVariables = (nodes) => {
    console.warn("filterForVariables")
    let result = {};
    for (const node of nodes) {
      if (Node.isVariable(node)) {
        result[node.id] = node;
      }
    }
    return Object.values(result);
  }
*/


  const getVariablesPointingAtCurrentNode = (id) => {
    return Node.filterForVariables(getNodesLinkingToID(id));
  }

  const getVariablesPointedToByCurrentNode = (id)  => {
    return Node.filterForVariables(getNodesLinkedFromID(id));
  }

  const getContextsPointingAtCurrentNode = (id) => {
    return Node.filterForContexts(getNodesLinkingToID(id));
  }

  const getContextsPointedToByCurrentNode = (id) => {
    return Node.filterForContexts(getNodesLinkedFromID(id));
  }

/*
  const getVariablesPointedToByCurrentNode = (id) => {
    console.warn("getVariablesPointedToByCurrentNode id is: " + id)
    let pointedToNodes = getNodesLinkedFromID(id);
    console.warn("pointingAtNodes are " + JSON.stringify(pointedToNodes))
    return filterForVariables(pointedToNodes);
  };
*/
/*
  const getVariablesPointingAtCurrentNode = (id) => {
    console.warn("getVariablesPointingAtCurrentNode id is: " + id)
    let pointingAtNodes = getNodesLinkingToID(id);
    console.warn("pointingAtNodes are " + JSON.stringify(pointingAtNodes))
    return filterForVariables(pointingAtNodes);
  };
*/
/*
  const getContextsPointedToByCurrentNode = (id) => {
    console.warn("getContextsPointedToByCurrentNode id is: " + id)
    console.info(`graph before call is ${JSON.stringify(getGraph())}`)
    let pointedAtNodes = getNodesLinkedFromID(id);
    console.info(`graph after call is ${JSON.stringify(getGraph())}`)
    console.warn("pointed at contexts are " + JSON.stringify(pointedAtNodes));
    return filterForContexts(pointedAtNodes);
  };
*/
/*
  const getContextsPointingAtCurrentNode = (id) => {
    console.warn("getContextsPointingAtCurrentNode id is: " + id)
    let pointingAtNodes = getNodesLinkingToID(id);
    console.warn("pointing at contexts are " + JSON.stringify(pointingAtNodes));
    return filterForContexts(pointingAtNodes);
  };
*/

  const getGroupsForVariable = (id) => {
    console.warn("getGroupsForVariable id is: " + id)
    return referenceGraph.db_get_groups_for_variable(id, token, mail);
  }

  const ContextValues = {
    getNodesLinkingToID: getNodesLinkingToID,
    getNodesLinkedFromID: getNodesLinkedFromID,
    getNodeForId: getNodeForId,
    getContextsPointingAtCurrentNode: getContextsPointingAtCurrentNode,
    getContextsPointedToByCurrentNode: getContextsPointedToByCurrentNode,
    getVariablesPointedToByCurrentNode: getVariablesPointedToByCurrentNode,
    getVariablesPointingAtCurrentNode: getVariablesPointingAtCurrentNode,
    executeNodeSearch: executeNodeSearch,
    getGroupsForVariable: getGroupsForVariable,
    getGraph: getGraph
  }

  return (
    <GraphContext.Provider value={ContextValues}>
      {children}
    </GraphContext.Provider>
  )
}

export function useGraph() {
  return useContext(GraphContext);
}
