import { InputElementContainerSideBySidePositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementPowerCalculation({ changeFunction }) {
  <InputElementContainerSideBySidePositioning
    label={"Power Calculation"}
  >
    <InputElementMultilineTextArea
      onChange={
        (event) => {
          changeFunction(event, "methods_power_calc");
        }
      }
    />
  </InputElementContainerSideBySidePositioning>
}
