import { Node } from "typescript_helpers";
import { useCurrentNodes } from "../contexts/currentNodes/provider";
import { useGraph } from "../contexts/graph/provider";
import { VMHistoryPanel } from "./layouts/VMHistoryPanel";
import { VMLinkPanel } from "./layouts/VMLinkPanel";
import { SelectedVariablesPanel } from "./layouts/SelectedVariablesPanel";

export function VariableRelations({ association }) {
  const { nodesManager } = useCurrentNodes();
  const { getContextsPointingAtCurrentNode, getContextsPointedToByCurrentNode, getVariablesPointingAtCurrentNode, getVariablesPointedToByCurrentNode, getNodeForId } = useGraph();

  const getCurrentId = () => {
    let maybe_id = nodesManager.getCurrentNodeForProposalAndKind(association.proposal_id, association.kind);
    if (maybe_id) return maybe_id;
    return "";
  }

  const getHeaderText = (node_id) => {
    const node = getNodeForId(node_id);
    if (Node.isVMContext(node)) {
      return node.display_name;
    }
    return "See Variable Description";
  }

  const sortedNodesToCurrent =
    Node.sortNodes(
      getContextsPointingAtCurrentNode(getCurrentId())
    ).concat(
      Node.sortNodes(
        getVariablesPointingAtCurrentNode(getCurrentId())
      )
    );
  const sortedNodesFromCurrent = Node.sortNodes(
    getContextsPointedToByCurrentNode(getCurrentId())
  ).concat(
    Node.sortNodes(
      getVariablesPointedToByCurrentNode(getCurrentId())
    )
  );
  return <>
    <VMLinkPanel
      headerText={
        getHeaderText(getCurrentId())
      }
      nodes={sortedNodesToCurrent}
      tooltip_text={"In this panel you can see a listing of all variables and topics that lead to the selected variable/topic"}
      association={association} />
    <VMLinkPanel
      headerText={"Associated with"}
      nodes={sortedNodesFromCurrent}
      tooltip_text={"In this panel you can see a listing of all variables and topics you can reach form the selected variable/topic. \n Example: Topics are highlighted in gray. Variables can be selected directly in this view."}
      association={association}
    />
    <SelectedVariablesPanel association={association} />
  </>
}
