export function Mailto({ email, subject = "", body = "", children, style }) {
  const params =
    subject || body
      ? `?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
          body
        )}`
      : "";

  return <a style={style} href={`mailto:${email}${params}`}>{children}</a>;
};

export function HCHSMail({style}) {
  return (
    <Mailto email="hchs-project@uke.de" style={style}>
      hchs-project@uke.de
    </Mailto>
  )
}
