import styled from "styled-components";
import { ColumnGrid, OverflowContainerVertical, RowGrid } from "../divider and frames/layouts";
import { PanelBorderStyling } from "./panel_styling";
import { useState } from "react";
import { useEffect } from "react";
import { LineHalf } from "../proposals/issued_proposals";
import { ContextLinkStyling, VariableLinkStyling } from "./vmlink";
import { useProposalSelectionMapping } from "../contexts/proposalsSelectionMapping/provider";
import { Header, NoticeText } from "../text_display/main_content_text_styling";
import { RedDividerBar, RedPartitionBar } from "../divider and frames/divider_bars";
import { useSelectedVariables } from "./variable_state_manager";
import { Node } from "typescript_helpers";
import { useAuth } from "../../authentication/auth_provider";
import { test_jwt, test_requestor_mail } from "../../nonComponentFunctions/test_config";
import { Proposal } from "../../nonComponentFunctions/Proposal";
import { InputElementButtonContainer } from "../text_inputs/main_content_inputs";
import { Submit } from "../buttons/menu_area_buttons";
import { usePageLayout } from "../page_layouts";
import { useNavigate } from "react-router";
import { useGraph } from "../contexts/graph/provider";


export const SorterPanel = styled(ColumnGrid)`
  margin-left: 15px;
  margin-right: 15px;
  padding: 15px;
  ${PanelBorderStyling}
  ${OverflowContainerVertical}
`
export function VariableSorter({association, proposal, onConfirmCallback }) {
  const {mail, token} = useAuth();
  const { getNodeForId } = useGraph();
  const { selectionManager } = useProposalSelectionMapping();

  const getSelectedIdsAndDisplayNames = (selected_ids) => {
    let result = [];
    for (const id of selected_ids){
      const node = getNodeForId(id, token, mail);
      const displayName = Node.getDisplayName(node);
      result.push({id: id, name: displayName});
    }
    return result;
  }

  const getVariablesToDisplay = () => {
    const variables = getSelectedIdsAndDisplayNames(selectionManager.getSelectionsForProposalAndKind(association.proposal_id, association.kind));

    if (variables.length > 0) {
      return (
        variables.map(({ id, name }, index) => {
          return (
            <SorterLine
              key={index}
              proposal_id={proposal.getProposalAttribute('id')}
              variable_id={id}
              displayName={name} />
          );
        })
      );
    }
    return <NoticeText>No Variables selected!</NoticeText>
  }

  return (
    <RowGrid template_rows={"auto 1fr auto"}
      rowGap={"0.5rem"}>
      <Header text={"Associate variables with selected proposal"} />
      <ListPanel rowGap={"2.5rem"}>
        {
          getVariablesToDisplay()
        }
      </ListPanel>
      <InputElementButtonContainer>
          <Submit button_text={"Confirm association"} callback={()=>{onConfirmCallback()}}/>
      </InputElementButtonContainer>
    </RowGrid>
  )
}

export function SorterLine({ proposal_id, variable_id, displayName }) {
  const [sel_out, set_out] = useState(false);
  const [sel_exp, set_exp] = useState(false);
  const [sel_con, set_con] = useState(false);

  const ProposalSelectionMapping = useProposalSelectionMapping();

  const updateSelections = () => {
    set_out(ProposalSelectionMapping.selectionManager.variableSelectedAsKindForProposal(variable_id, "outcome", proposal_id));
    set_exp(ProposalSelectionMapping.selectionManager.variableSelectedAsKindForProposal(variable_id, "exposure", proposal_id));
    set_con(ProposalSelectionMapping.selectionManager.variableSelectedAsKindForProposal(variable_id, "confounder", proposal_id));
  }

  const handleSelectionChange = (kind, status) => {
    let updatedManager = ProposalSelectionMapping.selectionManager.setSelection(proposal_id, variable_id, kind, status);
    ProposalSelectionMapping.setSelectionManager(updatedManager);
  }

  useEffect(() => {
    updateSelections();
  }, [])

  useEffect(() => {
    updateSelections();
  }, [ProposalSelectionMapping])


  return <ColumnGrid template_columns={"auto 1fr"} rowGap={"0.5rem"} column_gap={"0.5rem"}>
    <RedPartitionBar />
    <LineHalf>
      <LineHalf>
        <VariableLinkStyling
          displayName={"Confounder"}
          is_selected={sel_con}
          handleCheckboxClick={() => { handleSelectionChange("confounder", !sel_con) }}
          handleTextClick={() => { handleSelectionChange("confounder", !sel_con) }} />
        <VariableLinkStyling
          displayName={"Exposure"}
          is_selected={sel_exp}
          handleTextClick={() => { handleSelectionChange("exposure", !sel_exp) }}
          handleCheckboxClick={() => { handleSelectionChange("exposure", !sel_exp) }} />
        <VariableLinkStyling
          displayName={"Outcome"}
          is_selected={sel_out}
          handleTextClick={() => { handleSelectionChange("outcome", !sel_out) }}
          handleCheckboxClick={() => { handleSelectionChange("outcome", !sel_out) }} />
      </LineHalf>
      <LineHalf>
        <ContextLinkStyling displayName={displayName} />
      </LineHalf>
    </LineHalf>
  </ColumnGrid>
}

export const ListPanel = styled(RowGrid)`
  margin-left: 15px;
  margin-right: 15px;
  padding: 1rem;
  ${PanelBorderStyling}
  ${OverflowContainerVertical}
`

export function VariableProposalMapper({association}){
  const [proposal_selected, set_proposal_selected] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const navigate = useNavigate();
  const {closeOverlayModal} = usePageLayout();

  const selectProposalCallback = (proposal) => {
    setSelectedProposal(proposal);
    set_proposal_selected(true);
  }

  const createNewProposalCallback = () => {
    let proposal = new Proposal();
    navigate("/proposals/issueProposal_preselected_variables",
      {state: {proposal: proposal}});
    selectProposalCallback(proposal);
  }

  if(!proposal_selected){
    return (
      <ProposalSelectionList 
        selectProposalCallback={selectProposalCallback}
        createNewProposalCallback={createNewProposalCallback}/>
    )
  }
  return (
    <VariableSorter 
      proposal={selectedProposal} 
      onConfirmCallback={closeOverlayModal}
      association={association} />
  )
}

function ProposalSelectionList({selectProposalCallback, createNewProposalCallback}){
  const { mail, token } = useAuth();
  const [proposals, setProposals] = useState([]);

  const fetch_proposals = async () => {
    const proposals = await Proposal.db_get_proposals_for_user(mail, token);
    setProposals(proposals);
  }

  useEffect(()=>{
    fetch_proposals();
  }, [])

    return (
      <RowGrid  template_rows={"auto 1fr auto"}
        rowGap={"0.5rem"}>
        <Header text={"Select proposal to associate variables with"}/> 
        <ListPanel rowGap={"0.5rem"}>
          {proposals.map((proposal, index)=>{
            const proposal_name = proposal.getProposalAttribute('intro_project_title');
            return(
              <ContextLinkStyling 
                key={index}
                handleTextClick={()=>{selectProposalCallback(proposal)}}
                displayName={(proposal_name !== "" && proposal_name) || "missing proposal name"}/>
            )
          })}
        </ListPanel>
        <InputElementButtonContainer>
          <Submit button_text={"New Proposal"} callback={()=>{ createNewProposalCallback()}}/>
        </InputElementButtonContainer>
        
      </RowGrid>
    )
}

