import styled from "styled-components";
import { Header3 } from "../../text_display/main_content_text_styling";
import { ThemeColor } from "../../themes and styles/colors";

export const FormHeader = styled(Header3)`
  text-align: center;
  margin-top: 2rem;
  font-weight: bold;
  color: ${ThemeColor.uke.blue.normal};
`
