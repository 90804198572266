import { AuthProvider } from "./authentication/auth_provider";
import { SelectionProvider } from "./components/variablenmanual/variable_state_manager";
import { CurrentNodesProvider } from "./components/contexts/currentNodes/provider";
import { SelectionMappingProvider } from "./components/contexts/proposalsSelectionMapping/provider";
import { VMHistoriesProvider } from "./components/contexts/VMHistories/provider";
import { GraphProvider } from "./components/contexts/graph/provider";
import { CurrentNodeProvider } from "./components/contexts/currentNode/provider";
import { CurrentVMLinksProvider } from "./components/contexts/currentVMLinks/provider";
import { VariableHistoryProvider } from "./components/contexts/variableHistory/provider";
import graph from "./preprocessing/RacketScripts/var-manual-graph.json";

export function ContextProviders({ children }) {
  return (
    <AuthProvider>
      <GraphProvider graph={graph}>
        <CurrentNodeProvider>
          <CurrentNodesProvider>
            <CurrentVMLinksProvider>
              <SelectionProvider>
                <VMHistoriesProvider>
                  <VariableHistoryProvider>
                    <SelectionMappingProvider>
                      {children}
                    </SelectionMappingProvider>
                  </VariableHistoryProvider>
                </VMHistoriesProvider>
              </SelectionProvider>
            </CurrentVMLinksProvider>
          </CurrentNodesProvider>
        </CurrentNodeProvider>
      </GraphProvider>
    </AuthProvider>
  )
}
