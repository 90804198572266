import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UncollapseMenuArea } from "./buttons/menu_area_buttons";
import { MainInfoModal } from "./modals/main_info_modal";
import { ModalInput } from "./modals/modal";
import { OverlayContentModal } from "./modals/overlay_content_modal";
import { GreyDividerBar } from "./divider and frames/divider_bars";
import { RowGrid } from "./divider and frames/layouts";
import { useLocation } from "react-router";

const MainContent = styled.div`
    background-color: rgba(255, 255, 255, 1);
    overflow: auto;
    border-radius: 3px;
    flex-grow: 2;
    position: relative;
    height: calc(100% - 4rem);
    margin: 0.5rem 2rem;
    max-width: ${props => props.maxWidth || "70%"};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
`;

const MenuContent = styled.div`
    position: relative;
    margin: 1%;
    display: flex;
    justify-content: flex-end;
`;

const MenuContentTop = styled.div`
  overflow: visible;
  height: auto;
  max-height: 8rem;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const PageElementContainer = styled.div`
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
`;

const MenuAreaCollapsed = styled.div`
    position: absolute;
    top: 20px;
    right: 0px;
    margin-right: 4%;
    height: 30px;
    width: 30px;
    
`
const LayoutContext = createContext();

export const usePageLayout = () => { return useContext(LayoutContext) }

export function PageLayoutNavigationAreaTop(props) {

  const [menuCollapsedFlag, updateMenuCollapsedFlag] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(true);
  const [modalInput, setModalInput] = useState(null);
  const [overlayModalContent, setOverlayModalContent] = useState(null);
  const [showOverlayModal, setShowOverlayModal] = useState(false);
  const location = useLocation();

  const [menuArea, updateMenuArea] = useState(
    <MenuContentTop>{props.menu}</MenuContentTop>
  );

  const [mainContentWidth, updateMainContentWidth] = useState("70%");

  const activateModal = (modal_close_func, modal_processing_func) => {
    const close_callback = () => {
      setShowModalFlag(false);
      setModalInput(null);
      modal_close_func();
    };

    setModalInput(new ModalInput(close_callback, modal_processing_func))
    setShowModalFlag(true);
  }

  const activateOverlayModal = (modal_content) => {
    setOverlayModalContent(modal_content);
    setShowOverlayModal(true);
  }

  const closeOverlayModal = () => {
    setShowOverlayModal(false);
  }

  useEffect(()=>{
    if (location.pathname.endsWith("variablemanual")) {
      setContentWidthMax();
    }
    else {
      setContentWidthNormal();
    }
  }, [location.pathname])

  const toggleMenuCollapse = () => {
    if (!menuCollapsedFlag) {
      updateMenuArea(
        <MenuAreaCollapsed>
          <UncollapseMenuArea />
        </MenuAreaCollapsed>)
    }
    else {
      updateMenuArea(
        <MenuContentTop>
          {props.menu}
        </MenuContentTop>
      )
    }
    updateMenuCollapsedFlag(!menuCollapsedFlag);
  }

  const setContentWidthMax = () => {
    updateMainContentWidth("99%");
  }

  const setContentWidthNormal = () => {
    updateMainContentWidth("70%");
  }

  const value = {
    activateOverlayModal: activateOverlayModal,
    closeOverlayModal: closeOverlayModal,
    toggleMenuCollapse: toggleMenuCollapse,
    activateModal: activateModal,
    setContentWidthMax: setContentWidthMax,
    setContentWidthNormal: setContentWidthNormal
  }

  const DividerContainer = styled.div`
    display: flex;
    justify-content: center;
`

  return (
    <LayoutContext.Provider value={value}>
      {showModalFlag && modalInput !== null && <MainInfoModal modal_input={modalInput} />}
      {showOverlayModal && overlayModalContent !== null && <OverlayContentModal content={overlayModalContent} closeModalCallback={() => { closeOverlayModal() }} />}
      <PageElementContainer>
        <RowGrid template_rows={"auto auto 1fr"}>
          {menuArea}
          <DividerContainer>
            <GreyDividerBar />
          </DividerContainer>
          <MainContent maxWidth={mainContentWidth}>
            {props.content}
          </MainContent>
        </RowGrid>
      </PageElementContainer>
    </LayoutContext.Provider>
  )
}

export function PageLayoutNavigationAreaRight(props) {

  const [menuCollapsedFlag, updateMenuCollapsedFlag] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(true);
  const [modalInput, setModalInput] = useState(null);
  const [overlayModalContent, setOverlayModalContent] = useState(null);
  const [showOverlayModal, setShowOverlayModal] = useState(false);

  const [menuArea, updateMenuArea] = useState(
    <MenuContent>{props.menu}</MenuContent>
  );

  const activateModal = (modal_close_func, modal_processing_func) => {
    const close_callback = () => {
      setShowModalFlag(false);
      setModalInput(null);
      modal_close_func();
    };

    setModalInput(new ModalInput(close_callback, modal_processing_func))
    setShowModalFlag(true);
  }

  const activateOverlayModal = (modal_content) => {
    setOverlayModalContent(modal_content);
    setShowOverlayModal(true);
  }

  const closeOverlayModal = () => {
    setShowOverlayModal(false);
  }

  const toggleMenuCollapse = () => {
    if (!menuCollapsedFlag) {
      updateMenuArea(
        <MenuAreaCollapsed>
          <UncollapseMenuArea />
        </MenuAreaCollapsed>)
    }
    else {
      updateMenuArea(
        <MenuContent>
          {props.menu}
        </MenuContent>
      )
    }
    updateMenuCollapsedFlag(!menuCollapsedFlag);
  }

  const value = {
    activateOverlayModal: activateOverlayModal,
    closeOverlayModal: closeOverlayModal,
    toggleMenuCollapse: toggleMenuCollapse,
    activateModal: activateModal
  }

  return (
    <LayoutContext.Provider value={value}>
      {showModalFlag && modalInput !== null && <MainInfoModal modal_input={modalInput} />}
      {showOverlayModal && overlayModalContent !== null && <OverlayContentModal content={overlayModalContent} closeModalCallback={() => { closeOverlayModal() }} />}
      <PageElementContainer>
        <MainContent>{props.content}</MainContent>
        {menuArea}
      </PageElementContainer>
    </LayoutContext.Provider>
  )
}
