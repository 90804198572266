import { NoticeText } from "../../../text_display/main_content_text_styling";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementRationale({changeFunction}) {
  return <>

      <InputElementContainerTopDownPositioning
        label={"Background / Rational / Prior Data"}
      >
        <NoticeText>
          Explain the scientific background and rational for the investigation being reported
        </NoticeText>
        <InputElementMultilineTextArea
          onChange={
            (event) => {
              changeFunction(event, "intro_background");
            }
          }
        />
      </InputElementContainerTopDownPositioning>
  </>
}
