import { InputElementContainerSideBySidePositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementTypeOfPublication({ changeFunction }) {
  return (
    <InputElementContainerSideBySidePositioning
      label={"Planned Type of Publication"}
    >
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_presentation_type");
          }
        }
      />
    </InputElementContainerSideBySidePositioning>
  )
}
