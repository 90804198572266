import { FormHeader } from "./FormHeader";
import { FormElementBias } from "./methodology/bias";
import { FormElementPowerCalculation } from "./methodology/power_calculation";
import { FormElementSampleSize } from "./methodology/sample_size";
import { FormElementSetting } from "./methodology/setting";
import { FormElementTimeline } from "./methodology/timeline";

export function FormElementMethodology({ changeFunction }) {
  return (
    <>
      <FormHeader>Methodology</FormHeader>
      <FormElementSetting changeFunction={changeFunction} />
      <FormElementSampleSize changeFunction={changeFunction} />
      <FormElementPowerCalculation changeFunction={changeFunction} />
      <FormElementBias changeFunction={changeFunction} />
      <FormElementTimeline changeFunction={changeFunction} />
    </>
  )
}


