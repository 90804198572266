import { useState, useEffect } from "react";
import { InputElementCheckbox, InputItem } from "../../../text_inputs/main_content_inputs";

export function FormElementSettingSubQuestionaire({ changeFunction }) {
  const box1_label = "Baseline";
  const box2_label = "Clinical Follow-up";

  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  useEffect(() => {
    changeFunction({ target: { value: box1_selected } }, "methods_setting_baseline");
  }, [box1_selected])

  useEffect(() => {
    changeFunction({ target: { value: box2_selected } }, "methods_setting_yearly");
  }, [box2_selected])

  return (
    <>
      <InputItem>
        <InputElementCheckbox
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox1_selected(!box1_selected);
          }}
        />
        <InputElementCheckbox
          label={box2_label}
          checked={box2_selected}
          type={"checkbox"}
          onChange={(event) => {
            setBox2_selected(!box2_selected);

          }}
        />

      </InputItem>
      {/*box2_selected && 
        <OptionalTextArea 
          placeholder={"Indicate Year"} 
          onChangeCallback={(event)=>{changeFunction(event, "methods_setting_yearly_year")}}/>
      */}
    </>
  )
}


