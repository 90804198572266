//migrated
export const ThemeColor = {
  lightblue : "#1c79c1",
  red: "rgb(234, 0, 0)",
  lightred: "rgba(234, 0, 0, 0.26)",
  linkpurple: "rgb(76, 72, 157)",
  checkboxyellow: {
    normal: "#f9dd94",
    hover: "#f7d06e"
  },
  grey: {
    normal: "#D3D4DA",
    hover: "#DCDDE1"
  },
  uke: {
    blue: {
      normal: "rgb(0, 73, 146)",
      light: "rgba(0, 73, 146, 0.6)",
      lighter: "rgba(0, 73, 146, 0.3)"
    },
    font: {
      normal: "rgba(0, 0, 0, 0.8)",
      nav: {
        header: "rgba(9, 7, 24, 0.44)"
      },
      header: "rgba(0, 73, 146, 0.6)"
    }
  }
}
