import { useState } from "react";
import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementMultilineTextArea, InputElementSingleChoiceRadio } from "../../../text_inputs/main_content_inputs";

export function BiomaterialMultiProband({ changeFunction }) {
  const [multiProband, setMultiProband] = useState(false);

  return (
    <>
      <LabelSmall>
        Are There Multiple Determinations Per Propband Necessary?
      </LabelSmall>
      <InputElementSingleChoiceRadio
        box_selected_callback={
          (id) => {
            setMultiProband(id === "Yes")
          }
        }
        label_array={["Yes", "No"]}
      />
      {
        multiProband &&
        <>
          <LabelSmall>Reasoning</LabelSmall>
          <InputElementMultilineTextArea
            onChange={
              (event) => {
                changeFunction(event, "biomaterial_multiple");
              }
            }
          />
        </>
      }
    </>
  )
}


