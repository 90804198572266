import { InputElementContainerTopDownPositioning } from "../../../text_inputs/main_content_inputs"
import { FormElementMRISubSelection } from "./mri/mri_sub_selection"
import { FormElementSettingSubQuestionaire } from "./questionaire"
import { FormElementSettingSubOthers } from "./setting_others"

export function FormElementSetting({ changeFunction }) {

  return (
    <InputElementContainerTopDownPositioning
      label={"Setting"}
    >
      <FormElementSettingSubQuestionaire
        changeFunction={changeFunction}
      />
      <FormElementMRISubSelection
        changeFunction={changeFunction}
      />
      <FormElementSettingSubOthers
        changeFunction={changeFunction}
      />
    </InputElementContainerTopDownPositioning>
  )
}

