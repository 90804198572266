import { createContext, useContext, useEffect, useState } from "react";
import { isOneOf, VMHistoryManager } from "typescript_helpers";
import { useCurrentNodes } from "../currentNodes/provider";

const VMHistoriesContext = createContext();

export const VMHistoriesProvider = ({ children }) => {
  const [forceUpdateClock, setForceUpdateClock] = useState(false);
  const [historyManager, setHistoryManager] = useState(new VMHistoryManager());
  const [skipHistoryManagerUpdate, setSkipHistoryManagerUpdate] = useState(false);
  const { nodesManager, setNodesManager, forceUpdateClock: currentNodesUpdateClock } = useCurrentNodes();

  const Effect_updateHistory = () => {
    if (skipHistoryManagerUpdate) {
      setSkipHistoryManagerUpdate(false);
      return;
    }
    let { proposal_id, kind, node_id } = nodesManager.getLastTouchedElements();
    if (isOneOf("", [node_id, proposal_id, kind])) return;
    let newManager = historyManager.addToHistory(proposal_id, kind, node_id);
    setHistoryManager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const jumpBackInHistory = (proposal_id, kind, variable_id) => {
    let { revertStatus, manager } = historyManager.revertToLastIdInHistory(proposal_id, kind, variable_id);
    if (!revertStatus) return;
    let newNodesManager = nodesManager.setCurrentNode(proposal_id, kind, variable_id);
    setHistoryManager(manager);
    setSkipHistoryManagerUpdate(true);
    setForceUpdateClock(!forceUpdateClock);
    setNodesManager(newNodesManager);
  }

  const getLastHistoryElements = (proposal_id, kind, numberOfElements) => {
    return historyManager.getLastHistoryElements(proposal_id, kind, numberOfElements);
  }

  useEffect(() => {
    Effect_updateHistory();
  }, [currentNodesUpdateClock]);

  const ContextValues = {
    jumpBackInHistory: jumpBackInHistory,
    getLastHistoryElements: getLastHistoryElements,
    forceUpdateClock: forceUpdateClock
  }

  return (
    <VMHistoriesContext.Provider value={ContextValues}>
      {children}
    </VMHistoriesContext.Provider>
  )
}

export const useVMHistories = () => { return useContext(VMHistoriesContext); }
