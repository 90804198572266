import { useState } from "react";
import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementMultilineTextArea, InputElementSingleChoiceRadio } from "../../../text_inputs/main_content_inputs";

export function BiomaterialShipping({ changeFunction }) {
  const [needShipping, setNeedShipping] = useState(false);
  const [returnable, setReturnable] = useState(true);

  return (
    <>
      <LabelSmall>
        Will The Biomaterials Be Shipped?
      </LabelSmall>
      <InputElementSingleChoiceRadio
        box_selected_callback={
          (id) => {
            setNeedShipping(id === "Yes")
          }
        }
        label_array={["Yes", "No"]} />
      {
        needShipping &&
        <>
          <LabelSmall>
            To Whom Will They Be Shipped?
          </LabelSmall>
          <InputElementMultilineTextArea
            onChange={
              (event) => {
                changeFunction(event, "biomaterial_shipment");
              }
            }
          />
          <LabelSmall>Transport Company</LabelSmall>
          <InputElementMultilineTextArea
            onChange={
              (event) => {
                changeFunction(event, "biomaterial_transp_company");
              }
            }
          />
          <LabelSmall>
            Can The Materials Be Returned After Completion Of Measurements?
          </LabelSmall>
          <InputElementSingleChoiceRadio
            box_selected_callback={
              (id) => {
                setReturnable(id === "Yes")
              }
            }
            label_array={["Yes", "No"]}
          />
          {
            !returnable &&
            <>
              <LabelSmall>
                Reasoning For Materials Not Being Returnable
              </LabelSmall>
              <InputElementMultilineTextArea
                onChange={
                  (event) => {
                    changeFunction(event, "biomaterial_return");
                  }
                }
              />
            </>
          }
        </>
      }
    </>
  )
}
