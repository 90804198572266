import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementBiomaterialsProcedure({ changeFunction }) {
  return (
    <>
      <LabelSmall>
        Which Procedure Is Needed To Measure The Marker?
      </LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "biomaterial_procedure");
          }
        }
      />
    </>
  )
}
