import { NoticeText } from "../../../text_display/main_content_text_styling";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementBias({ changeFunction }) {
  return (
    <InputElementContainerTopDownPositioning
      label={"Bias and Limitation"}
    >
      <NoticeText>
        Describe any efforts to address potential sources of bias. Also discuss limitations of the study, taking into account sources of potential bias or imprecision. Discuss both direction and magnitude of any potential bias
      </NoticeText>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction("methods_bias_limitation");
          }
        }
      />
    </InputElementContainerTopDownPositioning>
  )
}
