import { BlueFrameBox, BlueFrameBoxTiny } from "../divider and frames/frame_boxes";
import styled from "styled-components";
import { ThemeColor } from "../themes and styles/colors";

export const Header2 = styled.h2`
    word-wrap: break-word;
    color: ${ThemeColor.uke.blue.normal};
    font-size: 2.3rem;
    margin-bottom: 2rem;
  `

export const Header3 = styled.h3`
  word-wrap: break-word;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${ThemeColor.uke.blue.normal};
`
export const Header4 = styled.h5`
  word-wrap: break-word;
  font-size: 1.0rem;
  font-weight: bold;
  padding: 0.2rem;
  margin: 0.2em 0.2em;
  justify-self: center;
  color: ${ThemeColor.uke.blue.normal};
`

export const HeaderVM = styled.h5`
  word-wrap: break-word;
  font-size: 1.0rem;
  font-weight: bold;
  padding: 0.2rem;
  margin: 0.em 0.2em;
  color: ${ThemeColor.uke.font.normal};
`
export function Header(props) {
  return (
    <Header2>{props.text}</Header2>
  )
}

export function HeaderSmall(props) {
  return (
      <Header3>{props.text}</Header3>
  )
}

export function HeaderTiny({ text }) {
  return (
    <Header4>{text}</Header4>
  )
}

export function HeaderVariable({text}) {
  return (
    <HeaderVM>{text}</HeaderVM>
  )
}

export const FullParentHeightBorderBox = styled.div`
  box-sizing: border-box;
`

export const NoticeText = styled.div`
    display: flex;
    background-color: rgb(240, 229, 251);
    padding: 8px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(215, 201, 230);
    margin-bottom: 4px;
    text-align: justify;
    font-size: small;
    flex: 1 1 0%;
`

//migrated
export const Padding = styled(FullParentHeightBorderBox)`
    padding: ${props => (props.padding && props.padding) || "12px 10px"};
    max-height: 100%;
`

//migrated
export const Label = styled.label`
    font-size: 16px;
    font-weight: 550;
    word-break: break-word;
    margin-left: 2px;
    margin-bottom: 2px;
    display: inline-block;
    color: ${ThemeColor.uke.font.normal};
`

//migrated
export const LabelSmall = styled.label`
    font-size: 12px;
    font-weight: 550;
    word-break: break-word;
    margin-left: 2px;
    margin-bottom: 3px;
    display: inline-block;
    color: ${ThemeColor.uke.font.normal};
`

//migrated
export const Indent = styled.div`
    margin-left: ${props => props.margin_left || "4px"};
    height: ${props => props.height || "100%"};
`

export function OptionalInputIndent({ children }) {
  return (
    <Indent margin_left={"20px"}>
      {children}
    </Indent>
  )
}

//migrated
export function MainContentElementContainer(props) {
  if (props.label === undefined) {
    return (
      <Padding padding={""}>
        {props.children}
      </Padding>
    )
  }

  let actualLabel;
  switch (props.labelsize) {
    case "small":
      actualLabel = <LabelSmall>{props.label}</LabelSmall>;
      break;
    case "normal":
      actualLabel = <Label>{props.label}</Label>;
      break;
    default:
      actualLabel = <Label>{props.label}</Label>;
  }

  return (
    <Padding padding={props.padding}>
      {actualLabel}
      {props.children}
    </Padding>
  )
}

export const MainContentGridLayout = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;
    grid-auto-rows: 1fr;
    overflow: hidden;
    row-gap: 1rem;
    column-gap: 1rem;
`

export const GridElement = styled(FullParentHeightBorderBox)`
    overflow: hidden;
`



