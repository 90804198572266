import { ColumnGrid } from "../../../divider and frames/layouts";
import { usePageLayout } from "../../../page_layouts";
import { LabelSmall, NoticeText } from "../../../text_display/main_content_text_styling";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";
import { VM } from "../../../variablenmanual/Variablemanual2";
import { ContextLinkStyling } from "../../../variablenmanual/vmlink";

export function FormElementNeededData(changeFunction, proposal_id) {
  const { activateOverlayModal } = usePageLayout();

  return <>
    <InputElementContainerTopDownPositioning label={"Data Needed (Variables)"}>
      <NoticeText>
        Clearly define all outcomes, exposures, predictors,
        potential confounders, and effect modifiers. Give
        diagnostic criteria, if applicable
      </NoticeText>
      <LabelSmall>Description Of Outcome / Dependent Variable(s)</LabelSmall>
      <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_outcome"); }} />
      <LabelSmall>Description Of Exposure / Independent Variable(s)</LabelSmall>
      <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_description"); }} />
      <LabelSmall>Description Of Confounder / Covariates</LabelSmall>
      <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_confounder"); }} />
      <ColumnGrid column_gap={"1rem"} template_columns={"repeat(auto-fit, minmax(235px, 1fr))"} >
        <ContextLinkStyling
          displayName={"Select Outcome Variables"}

          handleTextClick={() => { activateOverlayModal(<VM association={{proposal_id: proposal_id, kind: "outcome"}}/>) }} />
        <ContextLinkStyling displayName={"Select Exposure Variables"}
          handleTextClick={() => { activateOverlayModal(<VM association={{proposal_id: proposal_id, kind: "exposure"}}/>) }}
        />
        <ContextLinkStyling displayName={"Select Confounder Variables"}
          handleTextClick={() => { activateOverlayModal(<VM association={{proposal_id: proposal_id, kind: "confounder"}}/>) }}

        />
      </ColumnGrid>
    </InputElementContainerTopDownPositioning>
  </>
}
