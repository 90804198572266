//migrated all
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretSquareDown, 
  faCaretSquareUp, 
  faChevronLeft, 
  faChevronRight, 
  faCircleInfo as solidInfo, 
  faCircleQuestion as solidQuestion, 
  faLock, 
  faUser,
  faMagnifyingGlass,
  faDownload,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircleQuestion
}from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";
import { ThemeColor } from "../themes and styles/colors";

export function ArrowDown(props) {
  return <FontAwesomeIcon icon={faCaretDown} {...props}/>
}

export function UserIcon(props){
    return <FontAwesomeIcon icon={faUser} />;
}

export function LockIcon(props) {
    return <FontAwesomeIcon icon={faLock} />;
}

export function GreaterThanIcon(props){
    return <FontAwesomeIcon icon={faChevronRight} />;
}

export function LessThanIcon(props){
    return <FontAwesomeIcon icon={faChevronLeft} />;
}

export function ToggleUpIcon(props){
    return <FontAwesomeIcon icon={faCaretSquareUp}/>
}

export function ToggleDownIcon(props) {
    return <FontAwesomeIcon icon={faCaretSquareDown} />;
}

export function InfoIcon(props){
  return <FontAwesomeIcon icon={solidInfo}/>;
}

export function QuestionIcon(props){
  return <FontAwesomeIcon icon={faCircleQuestion}/>
}

export function MenuIcon(props){
  return <FontAwesomeIcon icon={faBars}/>
}

export function SearchIcon(props){
  return <FontAwesomeIcon icon={faMagnifyingGlass} />
}

export function DownloadIcon(props){
  return <FontAwesomeIcon icon={faDownload} />
}

export const RoundIconWrapper = styled.div`
  border-radius: 1rem;
  place-self: center;
  margin-left: ${props => props.margin_left || "0px"};
  padding: ${props => props.padding || "0px"};
  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`
