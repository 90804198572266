import { useEffect } from "react";
import { useCurrentNode } from "../contexts/currentNode/provider";
import { BorderGrid } from "../divider and frames/layouts";
import { useCurrentNodes } from "../contexts/currentNodes/provider";
import { VMGridLayout } from "./layouts/VMGridLayout";
import { VMSearchBar } from "./VMSearchBar";
import { VariableRelations } from "./VariableRelations";
import { DetailsPanel } from "./details_panel";
import { SelectedVariablesPanel } from "./layouts/SelectedVariablesPanel";
import { VMHistoryPanel } from "./layouts/VMHistoryPanel";

export function VM({association}) {
  const { nodesManager, setNodesManager, forceUpdateClock} = useCurrentNodes();

  useEffect(()=>{
    if (!nodesManager.currentNodeSetForProposalAndKind(association.proposal_id, association.kind)) {
      let newManager = nodesManager.setCurrentNode(association.proposal_id, association.kind, "Start / Overview");
      setNodesManager(newManager);
    }
  }, [])
  
  return (
    <BorderGrid background={"#edf1f3"} border_radius="1rem">
      <VMGridLayout>
        <VMSearchBar association={association} />
        <VariableRelations association={association} />
        <VMHistoryPanel association={association} />
        <DetailsPanel association={association} />
      </VMGridLayout>
    </BorderGrid>
  )
}
