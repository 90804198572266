import { List } from "immutable";
import { isList, isString } from "typescript_helpers";
import { RedDividerBar, UKEDividerBar } from "../divider and frames/divider_bars";
import { HeaderSmall, HeaderTiny, HeaderVariable, HeaderVM, Indent, Label } from "../text_display/main_content_text_styling";
import { TestCollapsible } from "../widgets/collapsible";
import styled from "styled-components";
import { OverflowContainerVertical, RowGrid } from "../divider and frames/layouts";
import { Node } from "typescript_helpers";
import { OuterPanel } from "./panel_styling";
import { useEffect, useState } from "react";
import { useAuth } from "../../authentication/auth_provider";
import { useCurrentNodes } from "../contexts/currentNodes/provider";
import { useProposalSelectionMapping } from "../contexts/proposalsSelectionMapping/provider";
import { useGraph } from "../contexts/graph/provider";

const ScrollingListContainer = styled.div`
  ${OverflowContainerVertical}
  padding: 15px;
  border-radius: 10px;
  padding-bottom: 20px;
`

const CheckboxLine = styled.span`
  float: right;
  margin-right: 30px;
  position: relative;
`

const CheckBox = styled.input`
  transform: scale(2);
  margin-left: 10px;
  position: relative;
`

const Link = styled.a`
  color: blue;
  font-size: 1rem;
`

function Detail({attributeContent, attributeName, visibility}){
  let content = attributeContent;
  if (isString(content) &&
    content.includes("[[") &&
    content.includes("]]")){
    content = content.replace("[[", "");
    content = content.replace("]]", "");
    content = <Link>{content}</Link>;
  }
  if(visibility === "intern" || visibility === "internal"){
    return <></>;
  }
  if(visibility === "public" || visibility === "visible"){
    return (
      <TestCollapsible attributeName={attributeName}>
        {content}
      </TestCollapsible>
    )
  }
}

function DetailsRenderer({details, variable_id}){
  let filteredDetails = Node.filterEmptyDetails(details);
  let detailComponents = filteredDetails.map((elem, index) => {
    return (
      <Detail 
        key={index} 
        attributeName={elem.attributeName}
        attributeContent={elem.attributeContent}
        visibility={elem.visibility}
      />
    )})

  if (isList(details) && details.length === 0) return <></>;
  return (
    <RowGrid rowGap={"1rem"}>
      <Detail 
        key={variable_id}
        attributeName={"Variable name"}
        attributeContent={variable_id}
        visibility={"public"}
      />
      {detailComponents}
    </RowGrid>
  )
}

function VariableDetailAndCheckboxLine({association, variable_id, selected}){
  const {selectionManager, setSelectionManager} = useProposalSelectionMapping();
  const handleCheckboxClick = () => {
    let newManager = selectionManager.setSelection(association.proposal_id, variable_id, association.kind, !selected);
    setSelectionManager(newManager);
  }
  
  return (
    <Indent margin_left={"24px"}>
      <Label>Details</Label>
      <CheckboxLine>
        Select Variable
        <CheckBox 
          type={"checkbox"}
          checked={selected}
          onChange={()=>{handleCheckboxClick()}} />
      </CheckboxLine>
    </Indent>
  )
}

function VariableRenderer({nodeLike, association}){
  const displayName = Node.getDisplayName(nodeLike);
  const {id, details } = nodeLike;
  const [selected, setSelected] = useState(false);
  const {selectionManager, forceUpdateClock} = useProposalSelectionMapping();

  const setSelection = () => {
    const selectedInManager = selectionManager.variableSelectedAsKindForProposal(id, association.kind, association.proposal_id);
    setSelected(selectedInManager);
  }
  useEffect(()=>{
    setSelection();
  },[id]);
  useEffect(()=>{setSelection();},[forceUpdateClock]);
  return <>
      <HeaderSmall text={"Variable Description"} />
      <HeaderVariable text={displayName} />
      <VariableDetailAndCheckboxLine 
        association={association}
        variable_id={id} 
        selected={selected}/>
      <UKEDividerBar />
      <DetailsRenderer details={details} variable_id={id}/>
    </>
}

function VMContextRenderer({nodeLike}){
  return <>
      <HeaderSmall text={"Context Description"} />
      <HeaderVariable text={Node.getDisplayName(nodeLike)} />

      <Indent margin_left={"24px"}>
        ToDo context explaination
      </Indent>
      <UKEDividerBar />
      <DetailsRenderer 
        details={nodeLike.details}
        variable_id={nodeLike.id} />
    </>
}

export function DetailsPanel({association}){
  const {mail, token} = useAuth();
  const {forceUpdateClock, nodesManager, setNodesManager} = useCurrentNodes();
  const {getNodeForId} = useGraph();
  const [rendered, setRendered] = useState(<></>);
  const [templateRows, setTemplateRows] = useState("");
  const [node, setNode] = useState(null);

  const processNode = (nd) => {
    setRendered(JSON.stringify(nd));
  }

  const getNode = () => {
    const maybe_id = nodesManager.getCurrentNodeForProposalAndKind(
      association.proposal_id,
      association.kind
    );
    if (maybe_id) {
      console.log("getting node for id " + maybe_id);
      let nd = getNodeForId(maybe_id, token, mail);
      setNode(nd);
      nd.add_change_listener(displayCurrentNode);
    }
  }

  const displayCurrentNode = (nd) => {
    if(nd !== null){
      console.log("displaying node node is", JSON.stringify(nd))
      const nodeLike = Node.getNodeData(nd);
      if(Node.isVMContext(nodeLike)){
        setTemplateRows("");
        setRendered(<VMContextRenderer nodeLike={nodeLike} />);
        return;
      }
      if(Node.isVariable(nodeLike, "VM_Fragebogen_Benjamin")){
        setTemplateRows("");
        setRendered(<VariableRenderer association={association} nodeLike={nodeLike} />);
        return;
      }
      else {setRendered(<div>{JSON.stringify(nd)}</div>)}
    }
    else {
      setRendered(<></>);
      setTemplateRows("");
    }

  }

  useEffect(()=>{
    getNode();
  }, [forceUpdateClock])
  useEffect(()=>{
    displayCurrentNode(node);
  }, [node]);
  useEffect(()=>{
    getNode();
  }, [])

  
  return (
    <OuterPanel
      grid_column={"2 / span 2"}
      template_rows={templateRows}
      rowGap={"6px"}>
      {rendered}
    </OuterPanel>
  )
}
