import { useCurrentNodes } from "../contexts/currentNodes/provider";
import { useGraph } from "../contexts/graph/provider";
import { SearchBar } from "../widgets/searchbar";

export function VMSearchBar({ association }) {
  const { executeNodeSearch } = useGraph();
  const { nodesManager, setNodesManager } = useCurrentNodes();

  const triggerSearch = (searchText) => {
    if (!searchText.trim().length) return;
    let id_searchNode = executeNodeSearch(searchText);
    let newManager = nodesManager.setCurrentNode(
      association.proposal_id,
      association.kind,
      id_searchNode
    );
    setNodesManager(newManager);
  }

  const onEnterTriggerSearch = (key, searchText) => {
    if (key === "Enter") triggerSearch(searchText);
  }

  return (
    <SearchBar
      callbackTriggerSearch={triggerSearch}
      callbackInputKeyDown={onEnterTriggerSearch}
      columnSpan={"1 / span 3"}
    />
  )
}
