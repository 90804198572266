import { isNonNullObject } from "typescript_helpers";
import { useVMHistories } from "../../../../contexts/VMHistories/provider";
import { Indent } from "../../../../text_display/main_content_text_styling";
import { ContextLink } from "../ContextLink";

export function HistoryEntry({ indentSpecification, node, is_current_entry, association }) {

  const { jumpBackInHistory } = useVMHistories();

  const handleTextClick = () => {
    if (!is_current_entry) {
      jumpBackInHistory(association.proposal_id, association.kind, node.id);
    }
  }

  const getMarginLeft = () => {
    if (!isNonNullObject(indentSpecification)) {
      return "0px";
    }
    const { indentFactor, baseIndentWidth, baseIndentUnit } = indentSpecification;
    return `${indentFactor * baseIndentWidth}${baseIndentUnit}`;
  }

  try {
    return (
      <Indent $height={"auto"} $margin_left={getMarginLeft()}>
        <ContextLink displayName={node.display_name} handleTextClick={handleTextClick} $background={is_current_entry && "lightblue" || ""} />
      </Indent>
    )
  }
  catch (err) {
    return <></>
  }
}
