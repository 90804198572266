import { useEffect, useState } from "react";
import { FormHeader } from "../FormHeader";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea, InputElementSingleChoiceRadio } from "../../../text_inputs/main_content_inputs";
import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { BiomaterialShipping } from "./shipping";
import { BiomaterialMultiProband } from "./proband";
import { FormElementBiomaterialsWhich } from "./which";
import { FormElementBiomaterialsNumber } from "./number";
import { FormElementBiomaterialsVolume } from "./volume";
import { FormElementBiomaterialsWhichMarkers } from "./markers";
import { FormElementBiomaterialsProcedure } from "./procedure";
import { FormElementBiomaterialsFinancing } from "./financing";
import { FormElementBiomaterialsWhere } from "./where";

export function FormElementBiomaterials({ changeFunction }) {
  const [activeBoxLabel, updateActiveBoxId] = useState("");
  const [needBio, setNeedBio] = useState(false);

  useEffect(() => {
    setNeedBio(activeBoxLabel === "Yes");
  }, [activeBoxLabel]);

  useEffect(() => {
    changeFunction(
      {
        target:
          { value: needBio }
      }, "need_bio");
  }, [needBio]);

  return (
    <>
      <FormHeader>Biomaterials</FormHeader>
      <InputElementContainerTopDownPositioning
        label={"Do You Need Biomaterials?"}>
        <InputElementSingleChoiceRadio
          box_selected_callback={(id) => { ; updateActiveBoxId(id) }}
          label_array={[
            "Yes",
            "No",
          ]} />

        {needBio &&
          <>
            <FormElementBiomaterialsWhich
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsNumber
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsVolume
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsWhichMarkers
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsProcedure
              changeFunction={changeFunction}
            />
            <BiomaterialMultiProband
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsFinancing
              changeFunction={changeFunction}
            />
            <FormElementBiomaterialsWhere
              changeFunction={changeFunction}
            />
            <BiomaterialShipping changeFunction={changeFunction} />
          </>}
      </InputElementContainerTopDownPositioning></>);
}


