import { useState } from "react";
import styled from "styled-components";
import { ScrollingContentPanel_override } from "../proposals/issued_proposals";
import { ColumnGrid } from "../divider and frames/layouts";
import { RoundIconWrapper, SearchIcon } from "../icons/font_awesome_icons";
import { Bold } from "../themes and styles/text_styles";
import { OuterPanel } from "../variablenmanual/panel_styling";

const SearchInput = styled.input`
  border: none;
  border-radius: 4px;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  font-weight: 600;
  transition: 0.2s;
  position: relative;
  margin: 0rem;
  margin-left: 0px;

  &:active,
    &:focus,
    &:hover {
        outline: none;
        border-bottom-color: #6a679e;
  }
`

function SearchWrapper({ children, bordered, columnSpan }) {
  const span = (columnSpan !== undefined && columnSpan) || "1";
  if (bordered) {
    return (
      <ScrollingContentPanel_override
        $template_rows={"1fr"}
        $grid_column={span}
      >
        {children}
      </ScrollingContentPanel_override>
    )
  }
  return (
    <OuterPanel grid_column={span}>
      {children}
    </OuterPanel>
  )
}

export function SearchBar({ callbackTriggerSearch, callbackInputKeyDown, columnSpan, bordered }) {

  const [searchText, setSearchText] = useState("");
  return (
    <SearchWrapper bordered={bordered} columnSpan={columnSpan}>
      <ColumnGrid template_columns={"auto 1fr"} column_gap={"1rem"}>
        <RoundIconWrapper $padding={"0rem"} $margin_left={"0.5rem"} onClick={() => { callbackTriggerSearch(searchText) }}>
          <SearchIcon />
          <Bold style={{ marginLeft: "0.5rem" }}>
            Search
          </Bold>
        </RoundIconWrapper>
        <SearchInput
          type="text"
          onKeyDown={(event) => {
            callbackInputKeyDown(event.key, searchText);
            setSearchText("");
          }}
          onChange={(event) => { setSearchText(event.target.value) }}
        />
      </ColumnGrid>
    </SearchWrapper>
  )
}
