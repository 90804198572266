import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import styled from "styled-components";
import {
  Indent,
  Label,
  MainContentGridLayout,
} from "../text_display/main_content_text_styling";
import "./out.css";
import { VariableGraph } from "./variable_graph";
import graph from "./../../preprocessing/RacketScripts/var-manual-graph.json";
import { HeaderSmall } from "../text_display/main_content_text_styling";
import { RedDividerBar } from "../divider and frames/divider_bars";
import { isList, isString } from "typescript_helpers";
import { Node } from "typescript_helpers";
import { OverflowContainerVertical, RowGrid } from "../divider and frames/layouts";
import { useSelectedVariables} from "./variable_state_manager";
import { OuterPanel, SelectedVariablesPanel } from "./panel_styling";
import {TestCollapsible} from "../widgets/collapsible";
import { VariableRelationListView } from "./variable_relation_list_view";
import { VMLink } from "./layouts/VMLink/VMLink";


export const ScrollingListContainer = styled.div`
  ${OverflowContainerVertical}
  padding: 0px;
  border-radius: 10px;
  padding-bottom: 20px;
`

const CheckBox = styled.input`
  transform: scale(2);
  margin-left: 10px;
  position: relative;
`

const CheckBoxLine = styled.span`
  float: right;
  margin-right: 30px;
  position: relative;
`

export const VariableAttributeContext = createContext(null);
export const VariableSelectionContext = createContext(null);

function VMContextRenderer(props) {
  return (
    <>
      <HeaderSmall text={Node.getDisplayName(props.nodeLike)} />
      <Indent margin_left={"24px"}>
        Dieser ist ein Kontext im Variablenmanual unter dem mehrere Variablen zusammengefasst sind
      </Indent>
      <RedDividerBar width={"95%"} />
      <DetailsRenderer details={props.nodeLike.details} id={props.nodeLike.id}/>
    </>
  )
}

export function VariableDetailAndCheckboxLine({ displayName, variable_id }) {
  const {getSelectedVariables, setSelectedVariables, isSelected} = useSelectedVariables();
  const handleCheckboxChange = (event) => {
    let selection = getSelectedVariables();
    selection[variable_id] = {displayName: displayName, is_selected: event.target.checked};
    setSelectedVariables(selection);
  }

  return (
    <Indent margin_left={"24px"}>
      <Label>Details</Label>
      <CheckBoxLine>
        Select Variable
        <CheckBox 
          type={"checkbox"} 
          defaultChecked={isSelected(variable_id)} 
          onChange={handleCheckboxChange}></CheckBox>
      </CheckBoxLine>
    </Indent>
  )
}

function VariableRenderer({ nodeLike }) {
  const displayName = Node.getDisplayName(nodeLike)
  const { id, details } = nodeLike;
  return (
    <>
      <HeaderSmall text={displayName} />
      <VariableDetailAndCheckboxLine
        variable_id={id}
        displayName={displayName} />
      <RedDividerBar width={"95%"} />
      <DetailsRenderer details={details} id={id}/>
    </>
  )
}

const Link = styled.a`
  color: blue;
  font-size: 1rem;
`

function Detail(props) {
  let content = props.attributeContent;
  if (isString(content) && content.includes("[[") && content.includes("]]")){
    content = content.replace("[[", "");
    content = content.replace("]]", "");
    console.log("if case in")
    content = <Link>
      {content}
    </Link>
  }
  
  return (
    <TestCollapsible attributeName={props.attributeName}>{content}</TestCollapsible>
  );
}

function VariableDetailsPanel(props) {
  /*const currentVarContext = useCurrentVariable();
  const nodeLike = { id: currentVarContext.currentID, details: currentVarContext.currentDetails };

  let Rendered = null;
  let template_rows = <></>;
  if (Node.isVMContext(nodeLike)) {
    template_rows = "auto auto auto 1fr"
    Rendered = <VMContextRenderer nodeLike={nodeLike} />
  }
  if (Node.isVariable(nodeLike, "VM_Fragebogen_Benjamin")) {
    template_rows = "auto auto auto 1fr"
    Rendered = <VariableRenderer nodeLike={nodeLike} />
  }

  return (
    <OuterPanel
      grid_column={"1"}
      template_rows={template_rows}
      rowGap={"6px"}
    >
      {Rendered}
    </OuterPanel>
  )
*/
}

function SelectedVariablesList() {
  const [selectedVariables, setSelectedVariables] = useState([]);
  const selectedVariablesContext = useSelectedVariables();
  const constructSelectedVariableComponents = (variables_state) => {
    let selectedVariables = [];
    for (const [variable_id, { is_selected, displayName }] of Object.entries(variables_state)) {
      if (is_selected) {
        selectedVariables.push(
          <VMLink 
            key={variable_id}
            is_selected={true}
            displayName={displayName}
            id={variable_id}
          />
        )
      }
    }
    setSelectedVariables(selectedVariables);
  }

  useEffect(()=>{
    constructSelectedVariableComponents(selectedVariablesContext.getSelectedVariables());
  },[selectedVariablesContext])


  return (
    <ScrollingListContainer>
      {selectedVariables}
    </ScrollingListContainer>
  )
}

function DetailsRenderer(props) {
  let filteredDetails = Node.filterEmptyDetails(props.details);
  let detailComponents = filteredDetails.map((elem, index) => {
    return <Detail key={index} attributeName={elem.attributeName} attributeContent={elem.attributeContent} />
  })
  if (isList(props.details && props.details.length === 0)) return <></>;
  return (
    <ScrollingListContainer>
      <Detail key={props.id} attributeName={"Variable name"} attributeContent={props.id}/>
      {detailComponents}
    </ScrollingListContainer>
  )
}

export function VariableManual(props) {
  const [currentVariableDetails, setCurrentVariableDetails] = useState([]);
  const [currentSelectionId, setCurrentSelectionId] = useState("");
  const value = {
    variableDetails: currentVariableDetails,
    setVariableDetails: setCurrentVariableDetails,
    currentSelectionId: currentSelectionId,
    setCurrentSelectionId: setCurrentSelectionId
  };

  return (
    <VariableAttributeContext.Provider value={value}>
          <MainContentGridLayout>
      {/*      <PanelVariableRelations>
              <VariableGraph
                nodes={graph.nodes}
                links={graph.links}
                rootNodes={["Start / Overview"][]}
              />
            </PanelVariableRelations>
      */}
            <VariableDetailsPanel />
            <SelectedVariablesPanel selectedVariables={<SelectedVariablesList />}/>
          </MainContentGridLayout>
    </VariableAttributeContext.Provider>
  );
}

export function VariableManualListStyle(){
  const [currentVariableDetails, setCurrentVariableDetails] = useState([]);
  const [currentSelectionId, setCurrentSelectionId] = useState("");
  const value = {
    variableDetails: currentVariableDetails,
    setVariableDetails: setCurrentVariableDetails,
    currentSelectionId: currentSelectionId,
    setCurrentSelectionId: setCurrentSelectionId
  };

  return ({/*
    <VariableAttributeContext.Provider value={value}>
          <MainContentGridLayout>
            <PanelVariableRelations>
              <VariableRelationListView/>
            </PanelVariableRelations>
            <VariableDetailsPanel />
            <SelectedVariablesPanel selectedVariables={<SelectedVariablesList />}/>
          </MainContentGridLayout>
    </VariableAttributeContext.Provider>
    */}
  );
}
