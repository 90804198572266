import { LabelSmall } from "../../../text_display/main_content_text_styling";
import { InputElementContainerTopDownPositioning, InputElementMultilineTextArea } from "../../../text_inputs/main_content_inputs";

export function FormElementCohortDefinition(changeFunction) {
  return (
    <InputElementContainerTopDownPositioning
      label={"Definition Of Cohort"}
    >
      <LabelSmall>Exclusion</LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_exclusion");
          }
        }
      />
      <LabelSmall>Inclusion</LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_inclusion");
          }
        }
      />
      <LabelSmall>Define Subgroups (if applicable)</LabelSmall>
      <InputElementMultilineTextArea
        onChange={
          (event) => {
            changeFunction(event, "analysis_subgroups");
          }
        }
      />
    </InputElementContainerTopDownPositioning>
  )
}


