import { Header, Padding, HeaderSmall } from "./text_display/main_content_text_styling";
import { Bold } from "./themes and styles/text_styles";
import { HCHSMail } from "./widgets/mailto";
function Section({ header, children }) {
  return (
    <p>
      <Bold>
        {header}:
      </Bold> {children}
    </p>
  )
}

function Question({ header, children }) {
  return (
    <>
      <Padding />
      <HeaderSmall
        text={header}
        margin={"1rem 0px"}
      />
      {children}
    </>
  )
}

function HowCreateAccount(props) {
  return (
    <Question header={"Wie erstelle ich einen HCHS Project Account?"}>
      <p>
        Um einen HCHS Project Account zu erstellen, muss die Klinik oder das Institut, an dem Sie arbeiten, Mitglied der HCHS sein. Dies ist Voraussetzung für die Nutzung der HCHS-Daten sowie den Zugriff auf das Variablenmanual. Eine UKE-Mailadresse ist für die Beantragung nicht erforderlich. Senden Sie einfach eine E-Mail mit Ihrem vollständigen Namen und Ihrer Institutszugehörigkeit an  <HCHSMail />  Sie erhalten anschließend eine E-Mail mit Ihren Zugangsdaten. Nach dem ersten Login können Sie das von uns vergebene Passwort im Accountmanagement durch ein eigenes Passwort ersetzen.
      </p>
    </Question>
  )
}

function HowRequestData(props) {
  return (
    <Question header={"Wie können Forschungsdaten beantragt werden?"}>
      <p>
        Voraussetzung zur Nutzung der Daten der HCHS ist eine HCHS Mitgliedschaft der Klinik oder des Instituts an dem Sie arbeiten.  Die im Epidemiologischen Studienzentrum (ESZ) erhobenen Daten werden grundsätzlich über einen Datennutzungsantrag über das Webportal der HCHS beantragt. Dabei werden drei Arten von Anträgen unterschieden:
      </p>
      <Section header={"Nutzungsantrag"}>
        <p>
          Der Nutzungsantrag begründet ein Projekt zur Datenauswertung bzw. Analyse von Probenmaterial mit oder ohne anschließende(r) Publikation.
        </p>
      </Section>
      <Section header={"Vorhabensanzeige"}>
        <p>
          Eine Vorhabensanzeige ermöglicht es, geplante Projekte bereits in einem frühen Stadium zu präsentieren. Diese Anzeigen werden ähnlich wie Nutzungsanträge beraten und entschieden, dienen jedoch nicht der Reservierung von Themen. Sie sind besonders für Projekte gedacht, bei denen die Finanzierung noch nicht gesichert ist, aber eine vorläufige Genehmigung des Forschungsvorhabens erforderlich ist. Eine Vorhabensanzeige muss daher noch nicht so detailliert ausgearbeitet sein wie ein Nutzungsantrag.
        </p>
      </Section>
      <Section header={"Fallzahlabfragen"}>
        <p>
          Eine Fallzahlabfrage dient einer Vorbereitung eines Antrags/Vorhabens. Sie darf nicht für Publikationen oder andere Veröffentlichungen genutzt werden. Wenden Sie sich hierfür an das Datenmanagement der HCHS (<HCHSMail />)
        </p>
      </Section>
    </Question>
  )
}

function WhichData(props) {
  return (
    <Question header={"Welche Daten können beantragt werden?"}>
      <p>
        Die HCHS stellt eine umfassende und vielseitige Datenbasis für wissenschaftliche Forschungsprojekte zur Verfügung. Insgesamt können etwa 900 Sekundärvariablen, 2.800 Fragebogendaten, 2.000 Gerätedaten, 70 Biomarker sowie 2.500 Untersuchungsvariablen genutzt werden.
        Die Datenerhebung basiert auf einer rund siebenstündigen Basisuntersuchung, die detaillierte Einblicke in die körperliche Gesundheit und Lebensweise der Teilnehmer:innen ermöglicht. Sie umfasst umfangreiche Untersuchungen der körperlichen Funktionen, bildgebende Verfahren wie MRT-Untersuchungen des Herzens und des Kopfes sowie eine Vielzahl von Fragebögen zu Vorerkrankungen, Lebensstilfaktoren und psychosozialen Aspekten. Eine genaue Beschreibung der verfügbaren Variablen und ihrer Erhebungsdetails finden Sie im Variablenmanual, das Ihnen im Webportal bereitgestellt wird. Dieses Manual bietet eine ideale Grundlage, um gezielt Variablen für Ihre Forschungsfragen auszuwählen.
      </p>
      <p>
        Aktuell stehen verschiedene Datensätze zur Verfügung:
      </p>
      <Section header={"First Data Release"}>
        <p>Dieses Datenset umfasst querschnittliche Daten der ersten 10.000 Teilnehmer:innen. Enthalten sind Untersuchungsdaten, Fragebögen sowie MRT-Daten des Herzens und des Kopfes. Zusätzlich sind auch Gerätedaten verfügbar. Die Daten beziehen sich auf Proband:innen, die zwischen Februar 2016 und November 2018 in die Studie eingeschlossen wurden.</p>
      </Section>
      <Section header={"Second Data Release"}>
        <p>Dieses Set enthält querschnittliche Daten von ca. 16.400 Teilnehmer:innen. Derzeit stehen Untersuchungsdaten und Fragebogen-Items zur Verfügung. Die Proband:innen dieses Releases wurden im Zeitraum von Februar 2016 bis Dezember 2021 eingeschlossen.</p>
      </Section>
      <Section header={"Klinisches Follow-Up"}>
        <p>Für ca. 16.400 Teilnehmer:innen sind zusätzlich Follow-up-Daten zu neu aufgetretenen Erkrankungen verfügbar. Damit stehen erstmals auch längsschnittliche Daten zur Verfügung, die eine Analyse von Veränderungen und Entwicklungen über die Zeit ermöglichen.</p>

        <p>Die unterschiedlichen Datensätze sind das Ergebnis von zwei Qualitätssicherungsprozessen, die zu verschiedenen Zeitpunkten durchgeführt wurden. Aus diesem Grund wurden die Daten in mehreren Releases bereitgestellt.</p>
      </Section>
    </Question>
  )
}

function WhatIsForschungsverbund(props) {
  return (
    <Question header={"Was ist der Forschungsverbund?"}>
      <p>
        Der Forschungsverbund ist ein Zusammenschluss von Wissenschaftleri:innen des UKEs. Sie haben es sich zur Aufgabe gemacht, die bevölkerungsbasierte, klinisch-epidemiologische Forschung und andere assoziierte Projekte im Rahmen der HCHS wissenschaftlich zu fördern.
      </p>
      <p>
        Zu den zentralen Aufgaben des Forschungsverbundes gehören:
      </p>
      <p>
        Betreuung und Förderung von Forschungsprojekten Durchführung und Förderung interdisziplinärer Zusammenarbeit Anregung, Vorabberatung und Planung von Projektanträgen sowie die Empfehlung über die Genehmigung/Ablehnung von Projektanträgen an den Lenkungsausschuss Anregung neuer Projekte in der Forschung Förderung des wissenschaftlichen Nachwuchses in der Forschung durch Förderung von Projektanträgen.
      </p>
      <p>
        Der Forschungsverbund tagt online jeden zweiten Dienstag im Monat um 16.30 Uhr. Eine Einladung erfolgt jeweils eine Woche vorher über den E-Mail Verteiler des Forschungsverbundes.
      </p>
      <p>
        Bei Fragen oder Aufnahme in den Forschungsverbund Verteiler kontaktieren Sie uns gerne.
      </p>
    </Question>
  )
}

function HowDataExport(props) {
  return (
    <Question header={"Wie läuft ein Datenexport ab?"}>
      <p>
        Nach der Genehmigung Ihres Nutzungsantrags durch den Forschungsverbund wird der Datenexport durch das Datenmanagement vorbereitet. Dabei wird der Datensatz pseudonymisiert und verschlüsselt übergeben. Für die Verschlüsselung verwendet die HCHS standardmäßig die freie Software VeraCrypt. Bitte stellen Sie sicher, dass Sie die Software rechtzeitig von Ihrer lokalen IT installieren lassen. Die Datenübergabe erfolgt in den Räumlichkeiten des Datenmanagements (Christoph-Probst-Weg 3, 2. OG). Der Datensatz steht Ihnen anschließend für eine Dauer von zwei Jahren zur Verfügung. Sollten Sie mehr Zeit benötigen, wenden Sie sich bitte an das Datenmanagement. Wir freuen uns zudem über Mitteilungen zu erfolgreichen Publikationen, die auf Grundlage der bereitgestellten Daten entstanden sind.
      </p>
    </Question>
  )
}

function Bioprobenübergabe(props) {
  return (
    <Question header={"Bioprobenübergabe"}>
      <p>
        Vor der Beantragung einer Bioprobenübernahme muss die Finanzierung gesichert sein. Dafür kann zunächst eine Vorhabensanzeige im Forschungsverbund gestellt werden. Sobald die Mittel bereitstehen, kann ein Projektantrag eingereicht werden, in dem der Abschnitt „Biomaterials“ ausgefüllt sein muss. Nach der Genehmigung im Forschungsverbund erfolgt die finale Abstimmung im Lenkungsausschuss. Erst nach dessen Zustimmung kann die Probenentnahme stattfinden.
      </p>
      <p>
        Zusätzlich müssen spezifische Anforderungen an das Probenmaterial, die Analyseverfahren sowie das bearbeitende Labor im Antrag berücksichtigt werden. Die Nutzung von Biobank-Proben wird anhand wissenschaftlicher Relevanz, der Verhältnismäßigkeit des Probenverbrauchs sowie bestehender Biomarkerbestimmungen bewertet. Ein sparsamer und effizienter Umgang mit den Proben ist verpflichtend, um die Anzahl an Auftau- und Gefrierzyklen zu minimieren.
      </p>
      <p>
        Die Übergabe der Proben erfolgt ausschließlich an die im Antrag benannte verantwortliche Person. Eine Identifikation oder Kontaktaufnahme mit Studienteilnehmenden ist nur mit Genehmigung des Lenkungsausschusses möglich und wird ausschließlich durch das Studienmanagement durchgeführt.
      </p>
    </Question>
  )
}

function Probenvernichtung(props) {
  return (
    <Question header={"Probenvernichtung"}>

    </Question>
  )
}

function WhereNutzungsordnung(props) {
  return (
    <Question header={"Wo befindet sich die Nutzungsordnung für das Daten- und Probenmaterial?"}>
      <p>
        Die Nutzungsordnung für Daten- und Probenmaterial befindet in der HCHS-Satzung – im Anhang VIII. Hier geht es zur Satzung.
      </p>
    </Question>
  )
}

function WhereReportIssue(props) {
  return (
    <Question header={"Wo melde ich ein Problem?"}>
      Wenn Sie Fragen haben oder Unterstützung bei der Erstellung eines Antrags benötigen, stehen wir Ihnen gerne zur Verfügung. Zögern Sie nicht, uns per E-Mail unter <HCHSMail /> zu kontaktieren – wir helfen Ihnen jederzeit weiter.
    </Question>
  )
}

function OverviewPersons(props) {
  return (
    <Question header={"Übersichtsliste des Forschungsverbundes mit Ansprechpartner:innen"}>
      <p>
        Die HCHS legt großen Wert auf die Förderung von Kooperationen zwischen Instituten und Kliniken. Zögern Sie nicht, sich mit anderen Einrichtungen in Verbindung zu setzen, um gemeinsam neue Projekte zu entwickeln.
      </p>
      <p style={{ background: "orange" }}>Liste Mit Fachbereich und Ansprechpartner:in einfügen</p>
    </Question>
  )
}

export function FAQPage(props) {
  return (
    <>
      <Padding>
        <Header text={"Häufig gestellte Fragen (FAQ)"} />
        <HowCreateAccount />
        <HowRequestData />
        <WhichData />
        <WhatIsForschungsverbund />
        <HowDataExport />
        <Bioprobenübergabe />
        {/*<Probenvernichtung />
        <WhereNutzungsordnung /> */}
        <WhereReportIssue />
        <OverviewPersons />
        <Padding padding={"4rem 0rem"} />
      </Padding>
    </>
  )
}
