import { createContext, useContext, useEffect, useState } from "react";
import { useGraph } from "../graph/provider";
import { useCurrentNode } from "../currentNode/provider";
import { Node } from "typescript_helpers";

const CurrentVMLinksContext = createContext();

export const CurrentVMLinksProvider = ({ children }) => {
  const graphContext = useGraph();
  const currentVarContext = useCurrentNode();

  const [contextLinkedFromID, newContextLinkedFromID] = useState([]);
  const [contextLinkingToID, newContextLinkingToID] = useState([]);
  const [variablesLinkedFromID, newVariablesLinkedFromID] = useState([]);
  const [variablesLinkingToID, newVariablesLinkingToID] = useState([]);

  const handleCurrentIDChange = (id) => {
    let linkedFrom = graphContext.getNodesLinkedFromID(id);
    let linkedTo = graphContext.getNodesLinkingToID(id);
    let clf = {};
    let clt = {};
    let vlf = {};
    let vlt = {};

    for (const node of linkedFrom) {
      if (Node.isVariable(node)) {
        vlf[node.id] = node;
      }
      if (Node.isVMContext(node)) {
        clf[node.id] = node;
      }
    }

    for (const node of linkedTo) {
      if (Node.isVariable(node)) {
        vlt[node.id] = node;
      }
      if (Node.isVMContext(node)) {
        clt[node.id] = node;
      }
    }

    newContextLinkedFromID(Object.values(clf));
    newContextLinkingToID(Object.values(clt));
    newVariablesLinkedFromID(Object.values(vlf));
    newVariablesLinkingToID(Object.values(vlt));
  }

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID);
  }, [currentVarContext])

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID)
  }, [])

  const ContextValues = {
    contextLinkedFrom: contextLinkedFromID,
    contextLinkedTo: contextLinkingToID,
    variablesLinkedFrom: variablesLinkedFromID,
    variablesLinkedTo: variablesLinkingToID
  }

  return (
    <CurrentVMLinksContext.Provider value={ContextValues}>
      {children}
    </CurrentVMLinksContext.Provider>
  )
}

export function useCurrentVMLinks() {
  return useContext(CurrentVMLinksContext);
}
